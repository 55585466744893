import { SelectPicker } from 'rsuite';
import { PostHogSendCollection } from '../../../../utils/PostHogSendEvents';

const dropdownData = [
  { id: 1, value: 'name', label: 'Ordem Alfabética (A-Z)' },
  { id: 2, value: '-name', label: 'Ordem Alfabética (Z-A)' },
  {
    id: 5,
    value: '-last_update',
    label: 'Data de Atualização (mais recentes)',
  },
  { id: 4, value: 'last_update', label: 'Data de Atualização (mais antigos)' },
];

const CollectionOrderBy = ({
  ordering,
  setOrdering,
  onOrdering,
  collectionCount,
}) => {
  return (
    <div className="monitoring-order-by">
      <label id="select-order-by" className="monitoring-order-by_label">
        <b>Ordenar por:</b>
      </label>
      <SelectPicker
        block
        data={dropdownData}
        style={{ margin: '10px 0px' }}
        placeholder={'Selecione uma ordenação'}
        value={ordering}
        onChange={() => {
          setOrdering();
          PostHogSendCollection('order_by_collection'); // POSTHOG_NEW
        }}
        onClose={onOrdering}
        cleanable={false}
        disabled={collectionCount === 0}
      />
    </div>
  );
};

export default CollectionOrderBy;
