import { ArchiveAdd, Copy, FolderAdd, SaveRemove, Tag } from 'iconsax-react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'rsuite';
import { Turivius } from '../../../../../ui';
import AddTagDecision from '../../../../collections/components/Collection/AddTagDecision';
import RemoveTagDecision from '../../../../collections/components/Collection/AddTagDecision/RemoveTagDecision';
import AddCardToReadLaterButton from '../Buttons/AddCardToReadLaterButton';
import CiteButton from '../Buttons/CiteButton';
import ExportCardsButton from '../Buttons/ExportCardsButton/ExportCardsButton';
import RemoveCardFromCollectionButton from '../Buttons/RemoveCardFromCollectionButton';
import SaveCardToCollectionButton from '../Buttons/SaveCardToCollectionButton';
import UnselectCardsButton, { unselectAllHandler } from './UnselectCardsButton';
import { cn } from '../../../../../lib/utils';
import { useCollections } from '../../../../../store/ducks/theCollections';
// import SummaryButton from '../Buttons/SummaryButton';

import { PostHogSendResultsCard } from '../../../../../utils/PostHogSendEvents';

const BulkActionBar = ({
  collection,
  collectionComments,
  collectionTags,
  query,
  theMatchResultsThatHaveCollections,
  showExportCardsAsCSV,
  showAddCardToReadLaterButton,
  showCiteButton,
  showSaveCardToCollectionButton,
  showRemoveCardFromCollectionButton,
  showAddTagDecision,
  showRemoveTagDecision,
  activePage,
  fetchData,
  currentPageBulkAction,
  pageView,
  selectedCardsNumber,
}) => {
  const { collections: theCollections } = useCollections();
  const [numTheCardsWithTag, setNumTheCardsWithTag] = useState(0);
  const [hasTriggered, setHasTriggered] = useState(false);

  const theCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );

  const dispatch = useDispatch();

  const theAllCollectionsInOne = useMemo(() => {
    if (!theCollections) return;
    const collections = Object.entries(theCollections)
      ?.map(([_, object]) => object.boards)
      .flat();
    return collections;
  }, [theCollections]);

  const isItInACollection = useMemo(() => {
    const cardIds = theCards?.map((card) => card.id);
    return cardIds.every((cardId) =>
      theMatchResultsThatHaveCollections.includes(cardId),
    );
  }, [theCards, theMatchResultsThatHaveCollections]);

  useEffect(() => {
    getNumTheCardsWithTag();
  }, [collectionTags, theCards]);

  function getNumTheCardsWithTag() {
    if (collectionTags == null) {
      setNumTheCardsWithTag(0);
      return;
    }

    const theCardIds = theCards.map((card) => card.id);
    let cnt = 0;
    Object.keys(collectionTags).map((tagIdx) => {
      theCardIds.forEach((cardId) => {
        if (collectionTags[tagIdx].cards.includes(cardId)) cnt += 1;
      });
    });

    setNumTheCardsWithTag(cnt);
  }

  useEffect(() => {
    if (selectedCardsNumber === 2 && !hasTriggered) {
      PostHogSendResultsCard('selected_decision', pageView); // POSTHOG_NEW
      setHasTriggered(true); // Impede o evento ser disparado em ocasiões desnecessarias
    }
  }, [selectedCardsNumber]);

  return (
    <div className="sticky top-0 z-10 flex flex-col justify-center items-center">
      <div
        className={cn(
          'bg-[#032378] w-full h-16 pr-4 flex ',
          showRemoveCardFromCollectionButton ? 'xl:justify-end xl:pr-4' : '',
        )}
      >
        <div className=" flex items-center w-auto">
          <Checkbox
            checked={theCards.length > 0}
            onChange={() => unselectAllHandler(dispatch, theCards)}
            className="p-0"
          />
          <p className="font-semibold text-sm text-white whitespace-nowrap">
            {theCards.length > 1
              ? `${theCards.length} Decisões selecionadas`
              : theCards.length
                ? `${theCards.length} Decisão selecionada`
                : ''}
          </p>
        </div>
        <div className="flex flex-row w-full items-center gap-4 justify-center">
          {/* Botão responsável por exportar em formato de planilha */}
          {/*           {showExportCardsAsCSV && (
            <ExportCardsButton
              cards={theCards}
              collectionComments={query ? null : collectionComments}
              collectionTags={query ? null : collectionTags}
              filename={
                query ? `busca-${query}` : `colecao-${collection?.name}`
              }
              sourceMetadataHyperlink={window.location.href}
              sourceMetadataValue={
                query ? `Pesquisa: ${query}` : `Coleção: ${collection?.name}`
              }
            />
          )} */}
          {/* {
            <Turivius.TuriviusTooltip
              trigger="hover"
              message="Copie as citações de todas as decisões"
              placement="bottom"
              minWidth
            >
              <SummaryButton
                cards={theCards}
                className="bg-[#102F7F]"
                appearance="primary-dark"
              />
            </Turivius.TuriviusTooltip>
          } */}
          {showAddCardToReadLaterButton && (
            <Turivius.TuriviusTooltip
              trigger="hover"
              message="Adicione a uma coleção chamada ler mais tarde"
              placement="bottom"
              minWidth
            >
              <div className="w-fit">
                <AddCardToReadLaterButton
                  cards={theCards}
                  context="Results"
                  icon={<FolderAdd color="#FFFFFF" size="16" />}
                  appearance="primary-dark"
                  currentPageBulkAction={currentPageBulkAction}
                />
              </div>
            </Turivius.TuriviusTooltip>
          )}
          {showCiteButton && (
            <Turivius.TuriviusTooltip
              trigger="hover"
              message="Copie as citações de todas as decisões"
              placement="bottom"
              minWidth
            >
              <div className="w-fit">
                <CiteButton
                  cards={theCards}
                  appearance="primary-dark"
                  icon={<Copy size={16} color="#FFFFFF" />}
                  className="bg-[#102F7F]"
                  currentPageBulkAction={currentPageBulkAction}
                >
                  Copiar citação
                </CiteButton>
              </div>
            </Turivius.TuriviusTooltip>
          )}
          {showSaveCardToCollectionButton && (
            <Turivius.TuriviusTooltip
              trigger="hover"
              message="Salve as decisões em coleções"
              placement="bottom"
            >
              <div className="w-fit">
                <SaveCardToCollectionButton
                  cards={theCards}
                  query={query}
                  theCollections={theAllCollectionsInOne}
                  isItInACollection={isItInACollection}
                  appearance="primary-dark"
                  icon={<ArchiveAdd color="#FFFFFF" size="16" />}
                  className="bg-[#102F7F]"
                  currentPageBulkAction={currentPageBulkAction}
                >
                  {`${collection ? 'Adicionar a outra coleção' : 'Salvar em coleções'}`}
                </SaveCardToCollectionButton>
              </div>
            </Turivius.TuriviusTooltip>
          )}
          {showRemoveCardFromCollectionButton && (
            <Turivius.TuriviusTooltip
              trigger="hover"
              message="Remove da coleção"
              placement="bottom"
            >
              <div className="w-fit">
                <RemoveCardFromCollectionButton
                  cards={theCards}
                  fetchData={fetchData}
                  context={'collection'}
                  collectionId={collection?.id?.toString()}
                  appearance="primary-dark"
                  icon={<SaveRemove color="#FFFFFF" size="16" />}
                  className="bg-[#102F7F]"
                  activePage={activePage}
                  currentPageBulkAction={currentPageBulkAction}
                  pageView={pageView}
                >
                  Remover da coleção
                </RemoveCardFromCollectionButton>
              </div>
            </Turivius.TuriviusTooltip>
          )}
          {showAddTagDecision && (
            <Turivius.TuriviusTooltip
              trigger="hover"
              message="Adiciona e sobrescreve os marcadores já existentes"
              placement="bottom"
              minWidth
            >
              <div className="w-fit">
                <AddTagDecision
                  cards={theCards}
                  collectionId={collection?.id?.toString()}
                  collectionTags={collectionTags}
                  hideTagName={true}
                  numIndicator={theCards.length}
                  appearance="primary-dark"
                  className="bg-[#102F7F]"
                  icon={<Tag size="16" color="#FFFFFF" />}
                  currentPageBulkAction={currentPageBulkAction}
                />
              </div>
            </Turivius.TuriviusTooltip>
          )}
          {showRemoveTagDecision && (
            <Turivius.TuriviusTooltip
              trigger="hover"
              message="Remove todos os marcadores"
              placement="bottom"
            >
              <div className="w-fit">
                <RemoveTagDecision
                  cards={theCards}
                  collectionId={collection?.id?.toString()}
                  collectionTags={collectionTags}
                  numTheCardsWithTag={numTheCardsWithTag}
                  appearance="primary-dark"
                  className="bg-[#102F7F]"
                  icon={<Tag color="#FFFFFF" size="16" />}
                  currentPageBulkAction={currentPageBulkAction}
                >
                  Remover marcador
                </RemoveTagDecision>
              </div>
            </Turivius.TuriviusTooltip>
          )}
        </div>
        <UnselectCardsButton
          theCards={theCards}
          className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-full"
        />
      </div>
    </div>
  );
};

export default BulkActionBar;
