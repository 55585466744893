import { DocumentFilter, GlobalSearch } from 'iconsax-react';

export const businessCases = [
  {
    icon: <GlobalSearch size="62" color="#84CAFF" />,
    title: 'Dashboard Geral',
    description:
      'Gere gráficos para análise da tendência geral do número das reclamatórias trabalhistas nos Tribunais.',
    route: '/dashboard-empresarial/visao-geral',
  },
  {
    icon: <DocumentFilter size="62" color="#84CAFF" />,
    title: 'Dashboard por Assunto',
    description:
      'Gere gráficos da tendência do número de reclamatórias envolvendo determinado pedido.',
    route: '/dashboard-empresarial/visao-assunto',
  },
];
