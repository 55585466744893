import { useEffect, useState } from 'react';
import { Toggle } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
import { Tooltip, Whisper } from 'rsuite';
import './SynonymsFilter.scss';
import { useLocation } from 'react-router-dom';

const SynonymsFilter = ({ context, filtersProps, AllProps, clear }) => {
  const [rrChecked, setRRChecked] = useState(false);
  const [rgChecked, setRGChecked] = useState(false);
  const [rrcChecked, setRRCChecked] = useState(false);
  const location = useLocation();

  const changePrecedente = (value, kind) => {
    // Obter o estado atual do localStorage
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = filtersLocal
      ? JSON.parse(filtersLocal)
      : { precedente_qualificado: [] };

    // Garante que `precedente_qualificado` seja sempre um array
    filtersLocal.precedente_qualificado =
      filtersLocal.precedente_qualificado || [];

    // Lógica para adicionar ou remover o `kind`
    if (value) {
      // Adicionar se não estiver presente
      if (!filtersLocal?.precedente_qualificado?.includes(kind)) {
        filtersLocal?.precedente_qualificado?.push(kind);
      }
    } else {
      // Remover se estiver presente
      filtersLocal.precedente_qualificado =
        filtersLocal?.precedente_qualificado?.filter((item) => item !== kind);
    }

    // Atualizar o localStorage
    localStorage.setItem('filters', JSON.stringify(filtersLocal));

    // Debug para verificar o que está sendo armazenado
    // console.log('Updated filtersLocal:', filtersLocal);

    const params = new URLSearchParams(window.location.search);
    let filters = safeJsonParse(params.get('filters')) || {};

    filters.precedente_qualificado = filtersLocal?.precedente_qualificado;

    params.set('filters', JSON.stringify(filters));

    // EXPLANATION: Usado para não ficar os caracters estranhos
    let paramsStringDecode = decodeURIComponent(params.toString());

    if (window.location.pathname == '/pesquisa/resultados') {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${paramsStringDecode}`,
      );
    }
  };

  const safeJsonParse = (str) => {
    try {
      const decodedStr = decodeURIComponent(str);
      return JSON.parse(decodedStr);
    } catch (e) {
      console.error('Erro ao decodificar ou parsear:', e);
      return null;
    }
  };

  function onChangeFilter(option, type) {
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: type,
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    switch (type) {
      case 'synonyms':
        filtersProps?.changeSynonyms(option);
        break;
      case 'recursosRepetitivos':
        setRRChecked((prev) => !prev);
        changePrecedente(option, 'rr');
        break;
      case 'repercussaoGeral':
        setRGChecked((prev) => !prev);
        changePrecedente(option, 'rg');
        break;
      case 'controversia':
        setRRCChecked((prev) => !prev);
        changePrecedente(option, 'rrc');
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    // Obter o estado inicial do localStorage
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = filtersLocal
      ? JSON.parse(filtersLocal)
      : { precedente_qualificado: [] };

    // Verificar se 'rr' e 'rg' estão no array precedente_qualificado
    const isRRChecked =
      filtersLocal?.precedente_qualificado?.includes('rr') || false;
    const isRGChecked =
      filtersLocal?.precedente_qualificado?.includes('rg') || false;
    const isRRCChecked =
      filtersLocal?.precedente_qualificado?.includes('rrc') || false;

    // Atualizar os estados iniciais com base no localStorage
    setRRChecked(isRRChecked);
    setRGChecked(isRGChecked);
    setRRCChecked(isRRCChecked);
  }, []);

  useEffect(() => {}, [filtersProps]);

  useEffect(() => {
    if (clear) {
      filtersProps?.changeSynonyms(true);
    }
  }, [clear]);

  return (
    <div className="synonyms-filter-styles">
      <div className="toggle-section">
        <label className="synonyms-toggle-label">
          Incluir Termos Semelhantes
        </label>
        <Toggle
          onChange={(value) => onChangeFilter(value, 'synonyms')}
          checked={filtersProps?.synonyms}
          disabled={false}
        />
      </div>
      <hr />
      <div className="toggle-group">
        <label
          style={{ color: 'black', fontFamily: 'Poppins', fontWeight: 500 }}
        >
          Buscar apenas{' '}
          <Whisper
            trigger="hover"
            placement="top"
            speaker={
              <Tooltip>
                Com esse filtro você pode selecionar precedentes qualificados
              </Tooltip>
            }
          >
            <div className="ml-2 py-0.7 px-2 max-h-5 inline-flex justify-center items-center bg-[#F79009] text-white font-semibold rounded-full cursor-default">
              Novo
            </div>
          </Whisper>
        </label>

        <div
          className="toggle-item"
          style={{
            marginTop: location.pathname == '/pesquisa' ? '4px' : '13px',
          }}
        >
          <h3
            style={{ color: 'black', fontFamily: 'Poppins', fontWeight: 400 }}
          >
            Recursos Repetitivos
          </h3>
          <div className="box-toggle">
            <Toggle
              onChange={(value) => onChangeFilter(value, 'recursosRepetitivos')}
              checked={rrChecked}
              disabled={false}
            />
            <span>
              {AllProps?.generalCount?.rr ? AllProps?.generalCount?.rr : 0}
            </span>
          </div>
        </div>
        <div
          className="toggle-item"
          style={{
            marginTop: location.pathname == '/pesquisa' ? '-2px' : '12px',
          }}
        >
          <h3
            style={{ color: 'black', fontFamily: 'Poppins', fontWeight: 400 }}
          >
            Repercussão Geral
          </h3>
          <div className="box-toggle">
            <Toggle
              onChange={(value) => onChangeFilter(value, 'repercussaoGeral')}
              checked={rgChecked}
              disabled={false}
            />
            <span>
              {AllProps?.generalCount?.rg ? AllProps?.generalCount?.rg : 0}
            </span>
          </div>
        </div>
        <div
          className="toggle-item"
          style={{
            marginTop: location.pathname == '/pesquisa' ? '-2px' : '12px',
          }}
        >
          <h3
            style={{ color: 'black', fontFamily: 'Poppins', fontWeight: 400 }}
          >
            Controvérsia
          </h3>
          <div className="box-toggle">
            <Toggle
              onChange={(value) => onChangeFilter(value, 'controversia')}
              checked={rrcChecked}
              disabled={false}
            />
            <span>
              {AllProps?.generalCount?.rrc ? AllProps?.generalCount?.rrc : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SynonymsFilter;
