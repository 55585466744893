import { useState } from 'react';
import { Button } from '../../../../../ds-components';
import { Col, Grid, Input, Row } from 'rsuite';
import CreateCollection from '../CreateCollection';

import { PostHogSendCollection } from '../../../../../utils/PostHogSendEvents';

const SearchCollection = ({
  title,
  onSearch,
  onChangeFilter,
  noCreate,
  id,
  type,
}) => {
  const [input, setInput] = useState('');

  function keyPressSearch(e) {
    if (e.key === 'Enter') {
      searchFilter(input);
    }
  }

  function searchFilter(value) {
    onChangeFilter(input);
    onSearch(value);
    PostHogSendCollection('searched_collection', null, type); // POSTHOG_NEW
  }

  return (
    <Grid fluid className="collection-search-container">
      <Row className="collection-search-container_header">
        <Col
          xl={21}
          lg={19}
          md={!noCreate ? 17 : 24}
          sm={!noCreate ? 17 : 24}
          xs={24}
        >
          <h5 className="collection-search-container_header_title" id={id}>
            {title}
          </h5>
        </Col>
        {!noCreate ? (
          <Col xl={3} lg={5} md={7} sm={7} xs={24}>
            <CreateCollection getCollection={onSearch} fullWidth />
          </Col>
        ) : null}
      </Row>
      <Row className="collection-search-container_search">
        <Col xl={21} lg={19} md={17} sm={17} xs={24}>
          <Input
            className="collection-search-container_search_input"
            placeholder={`Buscar em "${title}"`}
            value={input}
            onChange={setInput}
            onKeyUp={(e) => keyPressSearch(e)}
          />
        </Col>
        <Col xl={3} lg={5} md={7} sm={7} xs={24}>
          <Button
            className="collection-search-container_search_button"
            fullWidth
            size="sm"
            onClick={() => searchFilter(input)}
          >
            Buscar
          </Button>
        </Col>
      </Row>
      {}
    </Grid>
  );
};

export default SearchCollection;
