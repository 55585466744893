import React, { useState, useEffect, useRef } from 'react';
import { Button, Card } from '../../../../../ds-components';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { cards } from 'src/requesters/requesters/cards';
import { format, isValid, parse, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { Whisper, Tooltip } from 'rsuite';

import './tooltip.scss';
import { Turivius } from '../../../../../ui';

import { PostHogSendHomePage } from '../../../../../utils/PostHogSendEvents'

interface SearchHistoryItem {
  query: string;
  filters: Record<string, any>;
  user: number;
  dt: string;
  fields: string[];
  search_mode: string;
  synonyms: boolean;
}

interface Filter {
  field_name: string;
  default: any;
}

const RecentSearchBox: React.FC = () => {
  const history = useHistory();
  const [defaultFilters, setDefaultFilters] = useState<Record<string, any>>({});
  const [pagination, setPagination] = useState(1);
  const [paginationHistory, setPaginationHistory] = useState(1);
  const [topics, setTopics] = useState<SearchHistoryItem[]>([]);
  const [showHistory, setShowHistory] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const itemsPerPage = 5;
  const Height = window.innerHeight;
  const itemsPerPageHistory = Height <= 700 ? 6 : 10;
  const middleItem = Math.ceil(topics.length / 2);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    setLoading(true); // Ativa o loading
    try {
      const historyData = await cards.searchHistory();
      setTopics(historyData);
    } catch (error) {
      console.error(
        'Não foi possível acessar o histórico de pesquisas:',
        error,
      );
      setError(
        'Não foi possível carregar o histórico de pesquisas. Por favor, tente novamente mais tarde.',
      );
    }finally {
      setLoading(false); // Desativa o loading
    }
    
  };

  const handleSearchClick = ({
    query,
    filters,
    fields,
    search_mode,
    synonyms,
  }: {
    query: string;
    filters: Record<string, any>;
    fields: string[];
    search_mode: string;
    synonyms: boolean;
  }) => {
    const encodedFilters = encodeURIComponent(JSON.stringify(filters));
    const encodedFields = encodeURIComponent(JSON.stringify(fields));
  
    history.push(
      `/pesquisa/resultados?query=${encodeURIComponent(query)}&fields=${encodedFields}&search_mode=${search_mode}&synonyms=${synonyms}&filters=${encodedFilters}`
    );
  };

  const fetchAndNavigateToSearchResults = async (search: SearchHistoryItem) => {
    try {
      const { query, filters, fields, search_mode, synonyms } = search;
  
      // Se faltar algum parâmetro, forneça valores padrão (defensivo)
      const defaultFields = ['ementa', 'full_text'];
      const defaultSearchMode = 'normal';
      const defaultSynonyms = true;
  
      handleSearchClick({
        query,
        filters: filters || {},
        fields: fields || defaultFields,
        search_mode: search_mode || defaultSearchMode,
        synonyms: synonyms ?? defaultSynonyms,
      });
    } catch (error) {
      console.error('Erro ao navegar para os resultados:', error);
    }
  };

  const handlePreviousPageRecentSearches = () => {
    setPagination((prevPage) => {
      return Math.max(prevPage - 1, 1);
    });
  };

  const handleNextPageRecentSearches = () => {
    setPagination((prevPage) => {
      return Math.min(prevPage + 1, Math.ceil(topics.length / itemsPerPage));
    });
  };

  const handlePreviousPageHistory = () => {
    setPaginationHistory((prevPage) => {
      return Math.max(prevPage - 1, 1);
    });
  };

  const handleNextPageHistory = () => {
    setPaginationHistory((prevPage) => {
      return Math.min(
        prevPage + 1,
        Math.ceil(topics.length / itemsPerPageHistory),
      );
    });
  };

  const handleShowHistory = () => {
    PostHogSendHomePage('see_all_history') // POSTHOG_NEW
    setShowHistory(true);
    setPaginationHistory(1);
  };

  const handleCloseHistory = () => setShowHistory(false);

  const startIndex = (pagination - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = topics.slice(startIndex, endIndex);

  const startIndexHistory = (paginationHistory - 1) * itemsPerPageHistory;
  const endIndexHistory = startIndexHistory + itemsPerPageHistory;
  const currentItemsHistory = topics.slice(startIndexHistory, endIndexHistory);

  const formatDate = (dateString: string): string => {
    const date = parse(dateString, 'dd/MM/yyyy - HH:mm:ss', new Date());
    if (!isValid(date)) {
      console.warn('Formato de data inválido:', dateString);
      return 'Data inválida';
    }
    return format(date, "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
  };

  return (
    <div style={{ width: '361px' }}>
      <Card title="">
        <div className="recent-box">
          <div className="flex justify-between gap-5">
            <h2 className="title-box ">Pesquisas recentes</h2>
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Veja seu histórico de buscas</Tooltip>}
            >
              <button
                onClick={handleShowHistory}
                className="text-[#0069F5] text-xs"
              >
                Ver todas
              </button>
            </Whisper>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-20">
              <Turivius.SimplePreloader size='lg'></Turivius.SimplePreloader>
            </div>
          ) : currentItems.length > 0 ? (
            currentItems.map((topic, index) => (
              <React.Fragment key={index}>
                <div className="recent-box_content" key={index}>
                  <div className="flex justify-between items-center ">
                    <h4 className="font-semibold text-xs  custom-truncate w-64">
                      {topic.query}
                    </h4>
                    <div>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={<Tooltip>Ir para o resultado</Tooltip>}
                      >
                        <img
                          src="/back-square.svg"
                          alt="Icone voltar"
                          className="icon-back-square recent_box_icon-back-square cursor-pointer"
                          onClick={async (e) => {
                            e.stopPropagation();
                            await fetchAndNavigateToSearchResults(topic);
                            PostHogSendHomePage('search_for_history'); // POSTHOG_NEW
                          }}
                        />
                      </Whisper>
                    </div>
                  </div>
                  <div className="recent-box_images">
                    <img
                      src="/calendar.svg"
                      alt="Icone calendário"
                      className="icon-calendar"
                    />
                    <p className=" custom-text-color ml-1">
                      Pesquisando em {formatDate(topic.dt)}
                    </p>
                  </div>
                </div>
                {index < currentItems.length - 1 && (
                  <hr className=" border-t border-gray-300" />
                )}
              </React.Fragment>
            ))
          ) : (
            <p>Nenhuma pesquisa recente encontrada.</p>
          )}
          <hr className="my-2 border-t border-gray-300" />
          {/* Paginação */}
          <div className="recent-box_footer flex items-center justify-center mt-2">
            <span className="pagination">
              Página {pagination} de {Math.ceil(topics.length / itemsPerPage)}
            </span>
            <div className="flex space-x-2">
              <Button
                className="recent-box_button"
                appearance="secondary"
                size="xs"
                icon={<ArrowLeft2 color="#667085" size={16} />}
                onClick={handlePreviousPageRecentSearches}
                disabled={pagination === 1}
              >
                Anterior
              </Button>
              <Button
                className="recent-box_button"
                appearance="secondary"
                iconPosition="right"
                size="xs"
                icon={<ArrowRight2 color="#667085" size={16} />}
                onClick={handleNextPageRecentSearches}
                disabled={
                  pagination === Math.ceil(topics.length / itemsPerPage)
                }
              >
                Próxima
              </Button>
            </div>
          </div>
        </div>
      </Card>



      {showHistory && (
        <div className="container-content-history-search">
          <Card title="">
            <div className="flex flex-col h-full">
              <div className="relative flex-shrink-0">
                <h2 className="title-box-drawer">
                  Pesquisas recentes
                </h2>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Fechar</Tooltip>}
                >
                  <button
                    onClick={handleCloseHistory}
                    className="absolute top-2 right-2 text-xl"
                  >
                    &times;
                  </button>
                </Whisper>
              </div>
              <div className="history">
                <h3 className="text-desc-history">
                  Veja abaixo as suas pesquisas mais recentes
                </h3>
                {currentItemsHistory.length > 0 ? (
                  currentItemsHistory.map((topic, index) => (
                    <div className="expasive-box-content-history" key={index}>
                      <div className="list-history">
                        <h4 className="font-semibold ml-2 truncate w-64 ">
                          {topic.query}
                        </h4>
                        <div className="tooltip-container">
                          <img
                            src="/back-square.svg"
                            alt="Icone voltar"
                            className="icon-back-square recent_box_icon-back-square cursor-pointer"
                            onClick={async (e) => {
                              e.stopPropagation();
                              await fetchAndNavigateToSearchResults(topic);
                            }}
                          />
                          <div className="tooltip-text">
                            Ir para o resultado
                          </div>
                        </div>
                      </div>
                      <div className="recent-box_images">
                        <img
                          src="/calendar.svg"
                          alt="Icone calendário"
                          className="icon-calendar ml-2"
                        />
                        <p className="searched-in">
                          Pesquisando em {formatDate(topic.dt)}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nenhum histórico de pesquisa encontrado.</p>
                )}
                {/* paginação */}
                <div className="recent-box_button flex items-center justify-center mt-5">
                  <span className="pagination text-gray-600 text-xs">
                    Página {paginationHistory} de{' '}
                    {Math.ceil(topics.length / itemsPerPageHistory)}
                  </span>
                  <div className="flex space-x-2">
                    <Button
                      className="button-previous-and-next "
                      appearance="secondary"
                      size="xs"
                      icon={<ArrowLeft2 color="#667085" size={16} />}
                      onClick={handlePreviousPageHistory}
                      disabled={paginationHistory === 1}
                    >
                      Anterior
                    </Button>
                    <Button
                      className="button-previous-and-next"
                      appearance="secondary"
                      iconPosition="right"
                      size="xs"
                      icon={<ArrowRight2 color="#667085" size={16} />}
                      onClick={handleNextPageHistory}
                      disabled={
                        paginationHistory ===
                        Math.ceil(topics.length / itemsPerPageHistory)
                      }
                    >
                      Próxima
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
      {showHistory && <div className="backdrop" onClick={handleCloseHistory} />}

    </div>
  );
};

export default RecentSearchBox;
