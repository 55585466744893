import React from 'react';
import { Profile2User } from 'iconsax-react';
import { Dropdown } from 'rsuite';

import { PostHogSendHeader } from '../../../utils/PostHogSendEvents';

const InviteFriendOption = ({ eventKey }) => {
  const link = process.env.REACT_APP_INVITE_FRIEND;
  return (
    <Dropdown.Item eventKey={eventKey}>
      <button
        className="option-menu-default"
        onClick={() => {
          window.open(link, '_black');
          PostHogSendHeader('perfil_options', 'convidar_colega'); // POSTHOG_NEW
        }}
      >
        <Profile2User size="20" color="#0069F5" />
        <label> Convidar um colega de empresa </label>
      </button>
    </Dropdown.Item>
  );
};

export default InviteFriendOption;
