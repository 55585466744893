import { useState, useEffect } from 'react';
import { ArrowDown2, ArrowUp2, TickCircle } from 'iconsax-react';
import { RadioGroup, Radio } from 'rsuite';
import './NewDateFilter.scss';
import DataPickerRender from '../../../DataPickerRender';
import { format } from 'date-fns';

const NewDateFilter = ({
  filtersProps,
  tmpFilters,
  entities,
  filters,
  updateSelectedFilters,
  endDate,
  startDate,
  setDateRange,
  dateRange,
  trigger,
  clear,
  setClear,
  setDataOption,
}) => {
  const [isButtonsExpanded, setIsButtonsExpanded] = useState(true);
  const [isDateSectionExpanded, setIsDateSectionExpanded] = useState(true);
  const [selectedRange, setSelectedRange] = useState('any_date');
  const [isDateFilterDisabled, setIsDateFilterDisabled] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    let filtersFromUrl = {};
    try {
      const encodedFilters = params.get('filters') || '{}';
      filtersFromUrl = JSON.parse(encodedFilters);
    } catch (error) {
      console.error('Erro ao parsear o parâmetro filters:', error);
    }

    const today = new Date();
    const formattedToday = format(today, 'yyyy-MM-dd');
    const lastYearStart = format(
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
      'yyyy-MM-dd',
    );
    const last2YearsStart = format(
      new Date(today.getFullYear() - 2, today.getMonth(), today.getDate()),
      'yyyy-MM-dd',
    );
    const last5YearsStart = format(
      new Date(today.getFullYear() - 5, today.getMonth(), today.getDate()),
      'yyyy-MM-dd',
    );

    let filtersLocal = localStorage.getItem('whats_year');
    let filtersLocalFilters = localStorage.getItem('filters');
    filtersLocalFilters = JSON.parse(filtersLocalFilters);
    if (startDate === lastYearStart && endDate === formattedToday) {
      setSelectedRange(filtersLocal);
      localStorage.setItem('whats_year', 'last_year');
      setDateRange({ start: lastYearStart, end: formattedToday });
      setIsDateFilterDisabled(true);
    } else if (startDate === last2YearsStart && endDate === formattedToday) {
      setSelectedRange(filtersLocal);
      localStorage.setItem('whats_year', 'last_2_years');
      setDateRange({ start: last2YearsStart, end: formattedToday });
      setIsDateFilterDisabled(true);
    } else if (startDate === last5YearsStart && endDate === formattedToday) {
      setSelectedRange(filtersLocal);
      localStorage.setItem('whats_year', 'last_5_years');
      setDateRange({ start: last5YearsStart, end: formattedToday });
      setIsDateFilterDisabled(true);
    } else {
      localStorage.setItem('whats_year', 'any_date');
      setDateRange({
        start: filtersLocalFilters?.date_range[0],
        end: filtersLocalFilters?.date_range[1],
      });
      setDateRange({
        start: filtersLocalFilters?.date_range
          ? filtersLocalFilters?.date_range[0]
          : null,
        end: filtersLocalFilters?.date_range
          ? filtersLocalFilters?.date_range[1]
          : null,
      });
      setSelectedRange(filtersLocal);
      setIsDateFilterDisabled(false);
    }
  }, [startDate, endDate, trigger]);

  const toggleButtonsExpand = () => {
    setIsButtonsExpanded(!isButtonsExpanded);
  };

  const toggleDateSection = () => {
    // setIsDateSectionExpanded(!isDateSectionExpanded);
  };

  const handleRadioChange = (value) => {
    setDataOption(value);
    const today = new Date();
    let start;
    let end;
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);
    switch (value) {
      case 'last_year':
        localStorage.setItem('whats_year', 'last_year');
        start = format(new Date(today.getFullYear() - 1, 0, 1), 'dd/MM/yyyy');
        end = format(new Date(today.getFullYear(), 0, 1), 'dd/MM/yyyy');

        filtersLocal.date_range = {
          0: format(new Date(today.getFullYear() - 1, 0, 1), 'dd/MM/yyyy'),
          1: format(new Date(today.getFullYear(), 0, 1), 'dd/MM/yyyy'),
        };
        localStorage.setItem('filters', JSON.stringify(filtersLocal));
        setDateRange({ start, end });
        break;
      case 'last_2_years':
        localStorage.setItem('whats_year', 'last_2_years');
        start = format(new Date(today.getFullYear() - 2, 0, 1), 'dd/MM/yyyy');
        end = format(new Date(today.getFullYear(), 0, 1), 'dd/MM/yyyy');

        filtersLocal.date_range = {
          0: format(new Date(today.getFullYear() - 2, 0, 1), 'dd/MM/yyyy'),
          1: format(new Date(today.getFullYear(), 0, 1), 'dd/MM/yyyy'),
        };

        localStorage.setItem('filters', JSON.stringify(filtersLocal));
        setDateRange({ start, end });
        break;
      case 'last_5_years':
        localStorage.setItem('whats_year', 'last_5_years');
        start = format(new Date(today.getFullYear() - 5, 0, 1), 'dd/MM/yyyy');
        end = format(new Date(today.getFullYear(), 0, 1), 'dd/MM/yyyy');

        filtersLocal.date_range = {
          0: format(new Date(today.getFullYear() - 5, 0, 1), 'dd/MM/yyyy'),
          1: format(new Date(today.getFullYear(), 0, 1), 'dd/MM/yyyy'),
        };
        setDateRange({ start, end });
        localStorage.setItem('filters', JSON.stringify(filtersLocal));
        break;
      default:
        localStorage.setItem('whats_year', 'any_date');
        start = null;
        end = null;
        filtersLocal.date_range = {
          0: tmpFilters.date_range['0'],
          1: tmpFilters.date_range['1'],
        };

        localStorage.setItem('filters', JSON.stringify(filtersLocal));
    }

    setSelectedRange(value);

    const params = new URLSearchParams(window.location.search);
    let filters = safeJsonParse(params.get('filters')) || {};

    if (start && end) {
      filters.date_range = [start, end];
    } else {
      delete filters.date_range;
    }
    params.set('filters', JSON.stringify(filters));

    // TODO: Usado para não ficar os caracters estranhos
    let paramsStringDecode = decodeURIComponent(params.toString());

    if (window.location.pathname == '/pesquisa/resultados') {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${paramsStringDecode}`,
      );
    }
    setIsDateFilterDisabled(value !== 'any_date');
  };

  const safeJsonParse = (str) => {
    try {
      const decodedStr = decodeURIComponent(str);
      return JSON.parse(decodedStr);
    } catch (e) {
      console.error('Erro ao decodificar ou parsear:', e);
      return null;
    }
  };

  useEffect(() => {
    if (clear) {
      setSelectedRange('any_date');
      setClear(false);
    }
  }, [clear]);

  return (
    <div className="single-filter-box">
      <div className="box_header">
        <span className="single-filter-box_title">Filtre por data</span>
      </div>

      {isButtonsExpanded && (
        <div className="radio-group-container">
          <RadioGroup
            name="radioList_two"
            onChange={handleRadioChange}
            value={selectedRange}
          >
            <div className="container-group-data">
              <div className="radio-row">
                <label>
                  <Radio value="any_date">
                    <div className="icon-text">
                      {selectedRange == 'any_date' ? (
                        <TickCircle size="12" />
                      ) : (
                        ''
                      )}
                      Qualquer data
                    </div>
                  </Radio>
                </label>
                <label>
                  <Radio value="last_year">
                    <div className="icon-text">
                      {selectedRange == 'last_year' ? (
                        <TickCircle size="12" />
                      ) : (
                        ''
                      )}
                      Último ano
                    </div>
                  </Radio>
                </label>
              </div>
              <div className="radio-row">
                <label>
                  <Radio value="last_2_years">
                    <div className="icon-text">
                      {selectedRange == 'last_2_years' ? (
                        <TickCircle size="12" />
                      ) : (
                        ''
                      )}
                      Últimos 2 anos
                    </div>
                  </Radio>
                </label>
                <label>
                  <Radio value="last_5_years">
                    <div className="icon-text">
                      {selectedRange == 'last_5_years' ? (
                        <TickCircle size="12" />
                      ) : (
                        ''
                      )}
                      Últimos 5 anos
                    </div>
                  </Radio>
                </label>
              </div>
            </div>
          </RadioGroup>
        </div>
      )}
      <hr style={{ margin: '20px 2px 10px 2px' }}></hr>
      <div className="date-section-header" onClick={toggleDateSection}>
        <span
          className="date-section-title"
          style={{
            fontFamily: 'Poppins',
            color: 'black',
            fontWeight: 400,
            fontSize: '14px',
          }}
        >
          Escolher data específica
        </span>
        {/* <div className="toggle-icon">
          {isDateSectionExpanded ? (
            <ArrowUp2 color=" #0069F5" size={14} />
          ) : (
            <ArrowDown2 color=" #0069F5" size={14} />
          )}
        </div> */}
      </div>
      {isDateSectionExpanded && (
        <form className="tv-filters_content">
          {filters
            ? filters.map((filter, index) => (
                <DataPickerRender
                  key={index}
                  context="Results"
                  filter={filter}
                  tmpFilters={tmpFilters}
                  entities={entities}
                  filtersProps={filtersProps}
                  updateSelectedFilters={updateSelectedFilters}
                  isDateFilterDisabled={isDateFilterDisabled}
                  dateRange={dateRange}
                />
              ))
            : null}
        </form>
      )}
    </div>
  );
};

export default NewDateFilter;
