import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as LoginActions } from '../../../../store/ducks/login';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';
import { posthog, requester } from '../../../../requesters';
import { Message, toaster } from 'rsuite';
import { Button as TuringButton } from '../../../../ds-components';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { TURIVIUS_VERSION } from '../../../../app/App';
import TuriviusLoginSSO from '../components/TuriviusLoginSSO';

class SSO extends React.Component {
  state = {
    email: null,
    hash: null,
    page: 'login',
    requesting: false,
  };

  componentDidMount() {
    document.title = 'Login SSO';
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    if (token && token !== 'None') {
      this.sso(token);
    } else if (token === 'None') {
      setTimeout(() => {
        this.notificationError();
      }, 2000);
    }
  }
  notificationError = () => {
    toaster.push(
      <Message
        showIcon
        closable
        type="error"
        duration={5000}
        header={'Acesso Não Permitido'}
      >
        Não conseguimos realizar o login com esse email.
      </Message>,
    );
  };
  sso = (token) => {
    this.setState({
      requesting: true,
    });

    requester.setUserToken(token);
    requester.sso.getUser().then((data) => {
      this.props.setPermissions(data?.permissions);
      localStorage.setItem('token', token);
      localStorage.setItem('permissions', JSON.stringify(data?.permissions));
      localStorage.setItem('user', data.id);
      localStorage.setItem('tagger', data.tagger);
      this.profile(data.id);
    });

    const auth = getAuth();
    signInWithEmailAndPassword(auth, 'vitor@turivius.com', 'Turivius@2023')
      .then()
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
      });
  };

  profile = (id) => {
    requester.profiles.getById(id).then((profile) => {
      posthog.identify(profile.id);
      posthog.people.set({
        email: profile.email,
        company: profile.company.id,
        name: profile.name,
      });

      localStorage.setItem('company', profile.company.id);
      this.props.setCompany(profile.company);
      localStorage.setItem('profile', JSON.stringify(profile));
      this.props.setProfile(profile);

      localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));
      toaster.push(
        <Message
          showIcon
          closable
          type="success"
          duration={5000}
          header={'Acesso Liberado'}
        >
          Login realizado com sucesso!
        </Message>,
      );
      this.props.history.push('/');
    });
  };

  getPermissions(userId) {
    let permissions = {};
    requester.segmentation
      .getServiceSegmentationByUserId(userId, 'tributario')
      .then((res) => {
        permissions = { ...permissions, tributario: res };
      })
      .catch(() => {
        permissions = { ...permissions, tributario: {} };
      });
    requester.segmentation
      .getServiceSegmentationByUserId(userId, 'trabalhista')
      .then((res) => {
        permissions = { ...permissions, trabalhista: res };
      })
      .catch(() => {
        permissions = { ...permissions, trabalhista: {} };
      });

    setTimeout(() => {
      this.props.setPermissions(permissions);
    }, 5000);
  }

  login = (email) => {
    this.setState({
      requesting: true,
    });
    let host = email.email.split('@', 2)[1];
    requester.sso
      .getSSO(host)
      .then((data) => {
        if (data.url) {
          window.location.href = data.url;
        } else {
          this.setState({
            requesting: false,
          });
          toaster.push(
            <Message
              showIcon
              closable
              type="error"
              style={{ marginBottom: '50px' }}
              duration={5000}
              header={'Acesso indisponível'}
            >
              Acesso indisponível para esse e-mail
            </Message>,
          );
        }
      })
      .catch((err) => {
        this.setState({
          requesting: false,
        });
        toaster.push(
          <Message
            showIcon
            closable
            type="error"
            duration={5000}
            header={'Acesso indisponível'}
          >
            Acesso indisponível para esse e-mail
          </Message>,
        );
      });
  };

  render() {
    const { page, requesting } = this.state;
    return (
      <div className="login-container">
        <div className="login-container_login sso">
          <TuriviusLoginSSO
            inside
            logo="new_logo.png"
            page={page}
            fetching={requesting}
            goForgotPassword={() => this.setState({ page: 'recuperar' })}
            goLogin={() => this.setState({ page: 'login' })}
            onLog={this.login}
            onNewPassword={this.forgotPassword}
            loginHeader={
              <div className="login-header">
                <span className="txt-login-header">
                  Ainda não tem uma conta?
                </span>
                <TuringButton
                  onClick={() =>
                    window.open('https://checkout.turivius.com/', '_blank')
                  }
                  appearance="white"
                  outline
                >
                  Teste Grátis
                </TuringButton>
              </div>
            }
            loginFooter={
              <div className="login-footer">
                <span>
                  Ao fazer login na plataforma você concorda com as{' '}
                  <a
                    className="clk-login-footer"
                    href="https://www.turivius.com/politica-de-privacidade/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="txt-login-footer">
                      politicas de privacidade
                    </span>
                  </a>
                </span>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.login.loggingIn,
  email: state.login.email,
  hash: state.login.hash,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign(ProfilesActions, LoginActions), dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SSO));
