import React from 'react';
import { Col, Grid, Modal, Row } from 'rsuite';
import { Turivius } from '../../../../../ui';
import BarChartCustomTooltip from '../JurimetricsChart/BarChartCustomTooltip';
import BarChart from '../JurimetricsChart/BarCharts';
import HorizontalBarChart from '../JurimetricsChart/HorizontalBarChart';
import Heatmap from '../JurimetricsChart/Heatmap';
import NoJurimetrics from '../JurimetricsChart/NoJurimetrics';
import html2canvas from 'html2canvas';
import './SearchJurimetrics.scss';
import { posthog } from 'posthog-js';
import { Button } from '../../../../../ds-components';
import { ArrowCircleDown2 } from 'iconsax-react';
import { postHubSpotEvent } from '../../../../../services/hubspot';

import { PostHogSendResultPage } from '../../../../../utils/PostHogSendEvents';

class SearchNewJurimetrics extends React.Component {
  state = {
    openModal: false,
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };
  handleOpen = () => {
    this.setState({ openModal: true });
  };

  printDocument() {
    const input = document.getElementById('novos-graficos');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      var link = document.createElement('a');
      link.download = 'jurimetria.png';
      link.href = imgData;
      link.click();
    });

    posthog.capture('download_jurimetria', {
      action: 'download_jurimetria',
      search: window.location.search,
    });

    PostHogSendResultPage('download_jurimetry'); // POSTHOG_NEW

    postHubSpotEvent('app_downloadjurimetria', {
      hs_page_url: window.location.href,
    });
  }

  componentDidMount() {
    const { searchPlotsFunc, searchPlots, dataQuery } = this.props;

    // if (searchPlots.length === 0) {
    // searchPlotsFunc(dataQuery);
    // }
  }

  render() {
    const { openModal, labelComponent } = this.state;
    const {
      searchPlots,
      requesting,
      entities,
      noJurimetricEntities,
      countResults,
    } = this.props;

    return countResults && countResults > 0 ? (
      <div className="jurimetrics-card">
        <div className="jurimetrics-search-card-header jurimetrics-card-header">
          <h6 className="font-bold">Jurimetria</h6>

          <div className="jurimetrics-search-card-header_actions">
            <Button
              appearance="secondary"
              size="sm"
              disabled={!(searchPlots?.length > 0)}
              onClick={this.printDocument}
              icon={<ArrowCircleDown2 color="#667085" size={16} />}
              className="ml-3"
              data-testid="download-jurimetrics"
            >
              Download
            </Button>
          </div>
        </div>

        <div className="jurimetrics-card-content">
          <div>
            <Modal open={openModal} onClose={this.handleClose}>
              <Modal.Header>
                <Turivius.TuriviusPopover
                  trigger="hover"
                  message={
                    <p>
                      O Vision é a Inteligência Artificial Tributária da
                      Turivius.
                      <br /> Clique aqui e saiba mais
                    </p>
                  }
                >
                  <div
                    className="premium-filter-identifier-animated"
                    onClick={() =>
                      window.open(
                        'https://turivius.com/portal/conheca-o-vision-inteligencia-artificial-tributaria/',
                        '__blank',
                      )
                    }
                  >
                    <div className="premium-filter-identifier">
                      <img src="/premium.png" alt="premium filter" />
                      <p>POWERED BY VISION</p>
                    </div>
                  </div>
                </Turivius.TuriviusPopover>
              </Modal.Header>
              <Modal.Body>
                <h5>Como interpretar os gráficos? </h5>
                <br />
                <p>
                  Os gráficos mostram com precisão estatística como as cortes
                  decidiram sobre o tema da sua pesquisa ao longo dos anos.
                  <br />
                  Use os gráficos para:
                </p>
                <ul style={{ listStyleType: 'disc', paddingLeft: 40 }}>
                  <li style={{ listStyleType: 'disc' }}>
                    avaliar estatisticamente as chances de sucesso de um
                    processo,
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    descobrir quais cortes são mais receptivas àquele tema, e
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    sofisticar a forma que você apresenta seus produtos e
                    soluções para seus clientes.
                  </li>
                </ul>
                <p>
                  A jurimetria te ajuda a se diferenciar da sua concorrência e
                  refina sua estratégia jurídica.
                  <br />
                  Os gráficos são simples. Veja o vídeo abaixo e entenda como
                  interpretá-los.
                </p>
                <br />
                <div
                  style={{
                    height: 315,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <iframe
                    title="search jurimetrics explain"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/lLUZ-GC1OWA"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Turivius.TuriviusButton onClick={this.handleClose}>
                  Fechar
                </Turivius.TuriviusButton>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Grid fluid id="novos-graficos">
              {searchPlots?.length > 0 ? (
                <Row className="mt-2">
                  <Col xs={24} sm={24} md={24} lg={24}>
                    {searchPlots
                      .slice(searchPlots?.length > 6 ? 6 : 2)
                      ?.filter(
                        (sp) =>
                          sp.type === 'bar' &&
                          sp.data.config.id === 'result_by_month',
                      )
                      ?.map((sp, i) => (
                        <Col
                          key={`search-${i}`}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          className="my-2"
                        >
                          <BarChartCustomTooltip
                            id={`search-${i}`}
                            title={sp.data.config.labels.top}
                            legend={sp.data.config.labels.left}
                            data={sp.data.data}
                            keys={sp.data.config.keys}
                            indexBy={sp.data.config.indexBy}
                            maxValue={sp.data.config.maxValue}
                            getColors={sp.data.config.colors}
                            width={350}
                          />
                        </Col>
                      ))}
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24}>
                    {searchPlots
                      .slice(searchPlots?.length > 6 ? 6 : 2)
                      ?.filter(
                        (sp) =>
                          sp.type === 'bar' &&
                          sp.data.config.id != 'result_by_month',
                      )
                      ?.map((sp, i) => (
                        <Col
                          key={`search-${i}`}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          className="my-2"
                        >
                          <BarChart
                            id={`search-${i}`}
                            title={sp.data.config.labels.top}
                            legend={sp.data.config.labels.left}
                            data={sp.data.data}
                            keys={sp.data.config.keys}
                            indexBy={sp.data.config.indexBy}
                            maxValue={sp.data.config.maxValue}
                            getColors={sp.data.config.colors}
                          />
                        </Col>
                      ))}
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24}>
                    {searchPlots
                      .slice(searchPlots?.length > 6 ? 6 : 2)
                      ?.filter((sp) => sp.type === 'horizontal_bar')
                      ?.map((sp, i) => (
                        <Col
                          key={`search-${i}`}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          className="my-2"
                        >
                          <HorizontalBarChart
                            id={`search-${i}`}
                            title={sp.data.config.labels.top}
                            legend={sp.data.config.labels.left}
                            data={sp.data.data}
                            keys={sp.data.config.keys}
                            indexBy={sp.data.config.indexBy}
                            maxValue={sp.data.config.maxValue}
                            getColors={sp.data.config.colors}
                          />
                        </Col>
                      ))}
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24}>
                    {searchPlots
                      .slice(searchPlots?.length > 6 ? 6 : 2)
                      ?.filter((sp) => sp.type === 'heatmap')
                      ?.map((sp, i) => (
                        <Col
                          key={`search-${i}`}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          className="my-2"
                        >
                          <Heatmap
                            id={`search-${i}`}
                            title={sp.data.config.labels.top}
                            data={sp.data.data}
                            axisRightlegend={sp.data.config.labels.x}
                            legend={sp.data.config.labels.legend}
                            emptyColor={sp.data.config.colors.emptyColor}
                          />
                        </Col>
                      ))}
                  </Col>
                </Row>
              ) : (
                <NoJurimetrics jurimetricEntities={noJurimetricEntities} />
              )}
            </Grid>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default SearchNewJurimetrics;
/**339 */
