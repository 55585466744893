import { Modal, Button } from 'rsuite';
import { toast, Bounce } from 'react-toastify';
//import icon.
import {
  ChartCircle,
  StatusUp,
  Buildings,
  Map1,
  Add,
  Message,
} from 'iconsax-react';

import { ReactComponent as AiIcon } from '../../../assets/svgs/aiicon.svg';
import { requester } from '../../../requesters';
import './UpgradeModal.css';
import UpgradeSVG from '../../../assets/imgs/upgrade-box.png';

const UpgradeModal = ({ open, feature, conditionStatus, onClose }) => {
  const getActionsPermission = () => {
    const permissionString = localStorage.getItem('actions_permission');
    let permissions = {};

    try {
      permissions = JSON.parse(permissionString);
    } catch {}

    return permissions;
  };

  const actionPermission = getActionsPermission();

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      size="lg"
      className="upgrade-modal wrapper-modal"
    >
      <Modal.Header>
        {/* <IconButton
            icon={<FontAwesomeIcon icon={faTimes} />}
            appearance="subtle"
          /> */}
      </Modal.Header>

      <Modal.Body className="upgrade-modal">
        {/* Seção de Texto */}

        <div className="upgrade-modal-content">
          {conditionStatus == 'limit' ? (
            <p className="upgrade-modal-title">
              {`Ops! Você atingiu o limite ${actionPermission?.search_labor_jurimetry?.has_permission ? `de ${actionPermission?.search_labor_jurimetry?.has_permission}` : ' de '} consultas por mês.`}
            </p>
          ) : (
            <p className="upgrade-modal-title">
              Oops! Parece que você não tem acesso a essa ferramenta :(
            </p>
          )}

          <h4 className="upgrade-modal-subtitle">
            Faça um <span>upgrade de plano</span>{' '}
            {conditionStatus == 'limit'
              ? 'com pacote de consultas de CNPJ para poder usar sem limites'
              : 'e contrate nossa ferramenta de consultas de CNPJ.'}
          </h4>
          <hr className="separator-jurimetria-trabalhista"></hr>
          {/* Opções de Jurimetria */}
          <div className="upgrade-option">
            <div className="custom-box-icon">
              <Buildings color="#0069F5" size="24" />
            </div>
            <div>
              <h6 className="upgrade-option-title">Jurimetria por CNPJ</h6>
              <p className="upgrade-option-description">
                Tome decisões estratégicas com base no contencioso da empresa
                nos Tribunais.
              </p>
            </div>
          </div>

          <div className="upgrade-option">
            <div className="custom-box-icon">
              <Message color="#0069F5" size="24" />
            </div>
            <div>
              <div style={{ borderColor: 'red', padding: '2px' }}>
                <h6 className="upgrade-option-title">Jurimetria por Assunto</h6>
              </div>
              <p className="upgrade-option-description">
                Gere gráficos da tendência do número de reclamantes envolvendo
                determinado pedido nos Tribunais.
              </p>
            </div>
          </div>

          <div className="upgrade-option">
            <div className="custom-box-icon">
              <Map1 color="#0069F5" size="24" />
            </div>
            <div>
              <h6 className="upgrade-option-title">Jurimetria por Estado</h6>
              <p className="upgrade-option-description">
                Visualize a tendência de julgamentos de determinado Tribunal
                Regional do Trabalho.
              </p>
            </div>
          </div>

          <hr className="separator-jurimetria-trabalhista"></hr>
          {/* Botões */}
          <div className="upgrade-buttons">
            <Button
              className="custom-button-upgrade"
              onClick={() => {
                // EXPLANATION: Envia mensagem de update
                requester.segmentation.sendMessageUpgrade({ service: feature });
                // Customizando a posição via CSS para que o ícone não fique embaxio do octa desk
                const customToastStyle = {
                  marginBottom: '80px', // Ajusta a distância da borda
                };

                toast.success(
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <Message size="20" color="#28a745" />
                    Contato realizado com sucesso!
                  </div>,
                  {
                    position: 'bottom-right',
                    style: customToastStyle,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Bounce,
                    icon: false,
                  },
                );

                onClose();
              }}
            >
              <Add size="26" color="#fff" /> Solicitar upgrade
            </Button>
            <Button
              className="btn-cancelar"
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
          </div>
          <p className="upgrade-modal-title-bottom">
            Ao clicar em solicitar upgrade, nosso time de vendas irá entrar em
            contato com o administrador da conta do seu escritório.
          </p>
        </div>

        {/* <div className="upgrade-svg-container"> */}
        <img
          src={UpgradeSVG}
          className="upgrade-svg"
          alt="Upgrade Illustration"
        />
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default UpgradeModal;
