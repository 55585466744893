import React from 'react';
import { Dropdown } from 'rsuite';
import { withRouter } from 'react-router-dom';
import { DirectSend } from 'iconsax-react';

import { PostHogSendHeader } from '../../../utils/PostHogSendEvents';

const HireToolOption = ({ eventKey, ...props }) => {
  const link = process.env.REACT_APP_HIRE_TOOL;

  return (
    <Dropdown.Item eventKey={eventKey}>
      <button
        className="option-menu-default"
        onClick={() => {
          window.open(link, '_black');
          PostHogSendHeader('perfil_options', 'contratar_ferramenta'); // POSTHOG_NEW
        }}
      >
        <DirectSend size="20" color="#0069F5" />
        <label> Contratar a ferramenta </label>
      </button>
    </Dropdown.Item>
  );
};

export default withRouter(HireToolOption);
