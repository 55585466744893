import { useMemo, useState } from 'react';
import { Button } from '../../../../../ds-components';
import CustomNav from '../../../../account/admin/components/CustomNav';
import CreateTagButton from './CreateTagButton';
import SearchTag from './SearchTag';
import './style.scss';
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from '../../../../../components/ui/dialog';

const AddTagDecision = ({
  cards,
  collectionId,
  collectionTags,
  numIndicator,
  appearance,
  icon,
  className,
  currentPageBulkAction,
}) => {
  const tabMenu = useMemo(
    () => [
      {
        eventKey: 'search-tag',
        title: 'Buscar marcador',
      },
      {
        eventKey: 'create-tag',
        title: 'Criar marcador',
      },
    ],
    [],
  );
  const [open, setOpen] = useState(false);
  const [tabActive, setTabActive] = useState('search-tag');

  const cardIds = cards.map((card) => String(card.id));

  const tagName = useMemo(() => {
    if (numIndicator) return 'Adicionar marcador';
    const theTagName = collectionTags?.find((collectionTag) =>
      collectionTag.cards.find((cardId) => cardIds.includes(String(cardId))),
    );
    return theTagName?.title || 'Adicionar marcador';
  }, [collectionTags]);

  const handleCreateTag = () => {
    setTabActive('search-tag');
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger>
          <Button
            className={className}
            appearance={appearance}
            size="sm"
            icon={icon}
          >
            <div className="flex flex-row items-center justify-center">
              <span className="hide-text">{tagName}</span>{' '}
              {numIndicator != null && (
                <div className="ml-2 px-3 font-semibold rounded-xl bg-blue-50 text-blue-600">
                  {numIndicator}
                </div>
              )}
            </div>
          </Button>
        </DialogTrigger>
        <DialogContent className="min-w-[630px] translate-y-0 top-6">
          <DialogHeader className="modal-tag-container_header">
            <h6 className="modal-tag-container_header_title">
              Salvar marcador
            </h6>
            <p className="modal-tag-container_header_subtitle">
              Escolha um marcador para salvar, ou crie um novo para agrupar
              decisões com base em seus interesses.
            </p>
          </DialogHeader>

          <div className="modal-tag-container_body">
            <div className="modal-tag-container_body_tab-menu">
              <CustomNav
                items={tabMenu}
                appearance="subtle"
                active={tabActive}
                onSelect={setTabActive}
              />
            </div>

            <div className="modal-tag-container_body_content">
              {tabActive === 'search-tag' ? (
                <SearchTag
                  cards={cards}
                  collectionId={collectionId}
                  collectionTags={collectionTags}
                  currentPageBulkAction={currentPageBulkAction}
                />
              ) : (
                <CreateTagButton
                  cards={cards}
                  collectionId={collectionId}
                  collectionTags={collectionTags}
                  onCreateTag={handleCreateTag}
                />
              )}
            </div>
          </div>
          <DialogFooter className={`modal-tag-container_footer ${tabActive}`}>
            <Button
              size="sm"
              appearance="primary"
              onClick={() => setOpen(false)}
            >
              Concluído
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddTagDecision;
