import { useEffect, useState, useRef } from 'react';
import {
  Panel,
  Form,
  Schema,
  Col,
  Grid,
  Row,
  Button,
  Message,
  toaster,
} from 'rsuite';
import { requester } from '../../../../../requesters';
import { Turivius } from '../../../../../ui';
import './style.scss';

const companyModel = Schema.Model({
  name: Schema.Types.StringType().isRequired('Este campo é obrigatório.'),
  email: Schema.Types.StringType()
    .isEmail('Insira um e-mail válido')
    .isRequired('Este campo é obrigatório.'),
});

const CompanyInformations = ({ updateCompany }) => {
  const companyFormRef = useRef();
  const [disableButton, setDisableButton] = useState(true);
  const [companyFormValue, setCompanyFormValue] = useState({});
  const [companyFormError, setCompanyFormError] = useState({});
  const [load, setLoad] = useState(true);
  const [loadSend, setLoadSend] = useState(false);

  function setDefaultValues() {
    const { company } = JSON.parse(localStorage.getItem('profile'));

    setLoad(false);
    setDisableButton(true);

    setCompanyFormValue({
      name: company?.name,
      email: company?.email,
      description: company?.description,
      address: company?.address,
      last_modified: company?.last_modified,
      users_count: company?.active_users?.max_profiles || '--',
      users_created:
        company.active_users?.active + company.active_users?.inactive,
      users_active: company.active_users?.active,
    });
  }

  function validateValues() {
    setLoadSend(true);

    if (companyFormRef.current.check()) {
      const { name, email, description, address } = companyFormValue;

      requester.profiles
        .updateCompany(
          localStorage.getItem('company'),
          name,
          email,
          address,
          description,
          'Profiles',
        )
        .then((response) => {
          getProfile();
          updateCompany();
          setDisableButton(true);
          toaster.push(
            <Message
              showIcon
              style={{marginBottom:'50px'}}
              closable={true}
              duration={4000}
              type="success"
              header="Dados Atualizados"
            >
              Os dados foram atualizados com sucesso!
            </Message>,
          );
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message closeable duration={4000} style={{marginBottom:'50px'}} type="error">
              Houve um problema de conexão, tente novamente em instantes ou
              entre em contato com o suporte.
            </Message>,
          );
        })
        .finally(() => {
          setLoadSend(false);
        });
    } else {
      toaster.push(
        <Message closeable duration={4000} type="warning">
          Verifique os dados e tente novamente.
        </Message>,
      );
    }
  }

  const getProfile = () => {
    requester.profiles
      .getById(localStorage.getItem('user'))
      .then((response) => {
        localStorage.setItem('company', response.company.id);
        localStorage.setItem('profile', JSON.stringify(response));
        populateState();
      });
  };

  const populateState = () => {
    const { company } = JSON.parse(localStorage.getItem('profile'));

    setCompanyFormValue({
      name: company.name ? company.name : '',
      email: company.email ? company.email : '',
      address: company.address ? company.address : '',
      description: company.description ? company.description : '',
      last_modified: company?.last_modified,
    });

    setLoad(false);
  };

  useEffect(() => {
    setDefaultValues();
  }, ['']);

  return load ? (
    <Turivius.TuriviusPreloader />
  ) : (
    <Panel className="company-information" bordered>
      <Grid fluid>
        <Form
          fluid
          ref={companyFormRef}
          onChange={(formValue, e) => {
            setCompanyFormValue(formValue);
            setDisableButton(false);
          }}
          onCheck={setCompanyFormError}
          formError={companyFormError}
          formValue={companyFormValue}
          model={companyModel}
        >
          <Row>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="name">
                <Form.ControlLabel>Nome</Form.ControlLabel>
                <Form.Control
                  name={'name'}
                  placeholder={'Insira o nome da empresa'}
                />
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="email">
                <Form.ControlLabel>E-mail</Form.ControlLabel>
                <Form.Control readOnly name={'email'} placeholder={'--'} />
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="description">
                <Form.ControlLabel>Descrição</Form.ControlLabel>
                <Form.Control
                  readOnly
                  name={'description'}
                  placeholder={'--'}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={24} md={16}>
              <Form.Group controlId="address">
                <Form.ControlLabel>Endereço</Form.ControlLabel>
                <Form.Control
                  // readOnly
                  name={'address'}
                  placeholder={'--'}
                />
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="last_modified">
                <Form.ControlLabel>Última Atualização</Form.ControlLabel>
                <Form.Control readOnly disabled name={'last_modified'} />
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="users_count">
                <Form.ControlLabel>Usuários Contratados</Form.ControlLabel>
                <Form.Control readOnly disabled name={'users_count'} />
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="users_created">
                <Form.ControlLabel>Usuários Criados</Form.ControlLabel>
                <Form.Control readOnly disabled name={'users_created'} />
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="users_active">
                <Form.ControlLabel>Usuários Ativos</Form.ControlLabel>
                <Form.Control readOnly disabled name={'users_active'} />
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={24}>
              <Button
                loading={loadSend}
                onClick={validateValues}
                className="btn-save"
                style={{ float: 'right' }}
              >
                Salvar
              </Button>
              <Button
                disabled={disableButton}
                appearance="ghost"
                onClick={setDefaultValues}
                className="btn-cancel"
                style={{ float: 'right', marginRight: 5 }}
              >
                Desfazer alterações
              </Button>
            </Col>
          </Row>
        </Form>
      </Grid>
    </Panel>
  );
};

export default CompanyInformations;
