import { ArrowDown2, SearchNormal1 } from 'iconsax-react';
import React, { useEffect, useRef, useState } from 'react';
import styles from './FilterOrdered.module.scss';
import { PostHogSendCollection } from '../../../../utils/PostHogSendEvents';

export default function FilterOrdered({
  ordenarPor,
  setOrdenarPor,
  setFilter,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    'Selecione uma ordenação',
  );
  const [filterText, setFilterText] = useState('');
  const dropdownRef = useRef(null);

  const options = [
    'Ordem Alfabética (A-Z)',
    'Ordem Alfabética (Z-A)',
    'Data de Atualização (mais recentes)',
    'Data de Atualização (mais antigos)',
  ];

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleOptionClick = (option) => {
    setOrdenarPor(option);
    setSelectedOption(option);
    setDropdownOpen(false);
    PostHogSendCollection('order_by_collection'); // POSTHOG_NEW
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
    setFilter(event.target.value);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} ref={dropdownRef}>
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className={styles.button}
      >
        Ordenar por:{' '}
        <span className={styles.selectedOption}>
          {selectedOption}
          <ArrowDown2 size="12" color="#000" />
        </span>
      </button>

      {dropdownOpen && (
        <div className={styles.dropdown}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder="Pesquisar"
              className={styles.filterInput}
              value={filterText}
              onChange={handleFilterChange}
            />
            <SearchNormal1 className={styles.icon} size="16" color="#FF8A65" />
          </div>
          <ul>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option}
                  className={`${styles.option} ${
                    ordenarPor === option ? styles.active : ''
                  }`}
                  onMouseEnter={() => setHoveredOption(option)}
                  onMouseLeave={() => setHoveredOption(null)}
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </li>
              ))
            ) : (
              <li className={styles.noOptions}>Nenhum resultado encontrado</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
