import { useEffect, useState } from 'react';
import { Input, InputGroup } from 'rsuite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Nav, SpinningLoader } from '../../../../../ds-components';

import { Add, CloseCircle, SearchNormal } from 'iconsax-react';

import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import collections, {
  Creators as CollectionsActions,
} from '../../../../../store/ducks/collections';
import RemoveCardFromCollectionButton from './RemoveCardFromCollectionButton';
import AddCardToCollectionButton from './AddCardToCollectionButton';
import CreateAndAddToCollection from './CreateAndAddToCollection';
import TuriviusPreloader from '../../../../../ui/loaders/TuriviusPreloader';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../components/ui/dialog';
import { cn } from '../../../../../lib/utils';
import { useCollections } from '../../../../../store/ducks/theCollections';
import { useDispatch, useSelector } from 'react-redux';
import { Types as BulkactionTypes } from '../../../../../store/ducks/collectionsBulkaction';
import './SaveCardToCollectionButton.scss';

const SaveCardToCollectionButton = ({
  cards,
  query,
  isItInACollection,
  theCollections,
  appearance,
  icon,
  className,
  children,
  currentPageBulkAction,
  pageView,
}) => {
  const {
    fetchCollectionsByCards,
    loading: loadingTheCollections,
    modalCollections,
  } = useCollections();
  const [searchString, setSearchString] = useState('');
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      fetchCollectionsByCards(cards.map((card) => card.id));
    }
  }, [open]);

  const selectedCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );

  const clearSelectedCardsHandler = (dispatch) => {
    dispatch({ type: BulkactionTypes.UNSELECT_ALL });
  };

  const handleChangeNavigation = (index) => {
    setActiveIndex(index);
  };

  const clearSelectedCards = () => {
    clearSelectedCardsHandler(dispatch, selectedCards);
    setOpen(false);
  };

  const getCardsInCollection = (cards, collection) => {
    const collectionCardIds = collection.cards;
    const cardsInCollection = cards.filter((card) =>
      collectionCardIds.includes(card.id),
    );
    return {
      count: cardsInCollection.length,
      cards: cardsInCollection,
    };
  };

  const checkIfAllCardIdsHaveInACollection = (cards, collection) => {
    const cardIds = cards?.map((card) => card.id);
    const collectionCardIds = collection.cards;
    return cardIds.every((cardId) => collectionCardIds.includes(cardId));
  };

  const getCollectionName = (name) => {
    const maxLength = 17;
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };

  const theCollectionsFiltered = (collections) => {
    if (!collections || !Array.isArray(collections)) return [];

    const collectionFilters = collections.filter((collection) =>
      collection.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          searchString
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    );

    const mapFromCollections = new Map(
      collectionFilters.map((collection) => [collection.id, collection]),
    );

    return [...mapFromCollections.values()];
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) =>
        setOpen(isOpen ? isOpen : false, setSearchString(''))
      }
    >
      <DialogTrigger asChild>
        <Button
          appearance={appearance}
          icon={icon}
          size="sm"
          onClick={() => setOpen(true)}
          className={cn('save-collection-tour', className)}
          data-testid={isItInACollection}
          tooltipText="Salve a decisão em uma coleção"
        >
          <span className="hide-text">{children}</span>{' '}
        </Button>
      </DialogTrigger>
      <DialogContent
        style={{
          zIndex: 80,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          marginLeft: '38px',
        }}
        className="flex flex-col max-w-[658px] h-[501px]  translate-y-0"
      >
        <DialogHeader>
          <DialogTitle className="save-collection_header font-semibold">
            Salvar em coleção
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col overflow-y-auto pr-3 h-[370px]">
          <span className="mb-[23px] mt-[15px]">
            Escolha uma coleção para salvar, ou crie uma nova.
          </span>
          <Nav
            navItems={[
              {
                id: 1,
                title: (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <SearchNormal size={12} color="#0069f5" /> Buscar coleção
                  </span>
                ),
              },
              {
                id: 2,
                title: (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Add size={12} color="#0069f5" /> Criar coleção
                  </span>
                ),
                'data-testid': 'modal-create-collection-tab',
              },
            ]}
            activeTab={activeIndex}
            handleChange={handleChangeNavigation}
          />

          {activeIndex === 1 && (
            <div className="gap-6 flex flex-col mt-[15px] ml-1">
              <div style={{ border: '2px solid #E4E7EC', borderRadius: '4px' }}>
                <InputGroup inside>
                  <InputGroup.Button>
                    <SearchNormal size={12} color="#98A2B3" />
                  </InputGroup.Button>
                  <Input
                    autoFocus
                    placeholder="Buscar coleção por nome"
                    size="sm"
                    value={searchString}
                    onChange={(event) => setSearchString(event)}
                  />
                </InputGroup>
              </div>

              <div className="flex flex-col min-h-28">
                {loadingTheCollections && (
                  <div className="absolute" style={{ left: '46%', top: '54%' }}>
                    <TuriviusPreloader />
                  </div>
                )}

                {!loadingTheCollections &&
                  theCollectionsFiltered(modalCollections).length === 0 && (
                    <span>Você não tem coleções</span>
                  )}
                {!loadingTheCollections &&
                  Array.isArray(modalCollections) &&
                  theCollectionsFiltered(modalCollections || []).map(
                    (collection, key) => {
                      if (!collection) return null;
                      // console.log(collection, 'collection');
                      const {
                        count: cardsInCollectionCount,
                        cards: cardsInCollection,
                      } = getCardsInCollection(cards, collection);

                      const doesItHaveAll =
                        cardsInCollectionCount === cards.length;
                      return (
                        <div
                          className="flex justify-between items-center mb-4"
                          key={`${collection.name}-${key}`}
                        >
                          <span style={{ fontSize: '14px', color: '#475467' }}>
                            {getCollectionName(collection.name)}
                          </span>

                          <div
                            className={`flex items-center justify-between gap-2`}
                            style={{
                              width: `${
                                collection.does_it_have_all ||
                                collection.matching_count === 0
                                  ? '144px'
                                  : '68%'
                              } `,
                            }}
                          >
                            {!collection.does_it_have_all &&
                              collection.matching_count > 0 && (
                                <div
                                  className="flex"
                                  style={{
                                    alignItems: 'baseline',
                                    position: 'relative',
                                  }}
                                >
                                  <span
                                    className="text-xs font-bold"
                                    style={{ color: ' #98A2B3' }}
                                  >
                                    {cardsInCollectionCount}
                                    {''}
                                    {cardsInCollectionCount === 1
                                      ? ' decisão já adicionada.'
                                      : ' decisões já adicionadas.'}
                                  </span>
                                  <RemoveCardFromCollectionButton
                                    noconfirm
                                    textRemove={true}
                                    cards={cardsInCollection}
                                    collectionId={collection.id}
                                    context="Results"
                                    appearance={'danger'}
                                    icon={<span>x</span>}
                                    loadingIcon={
                                      <SpinningLoader
                                        color={'white'}
                                        size="sm"
                                      />
                                    }
                                    className="h-8 w-36"
                                    currentPageBulkAction={
                                      currentPageBulkAction
                                    }
                                    pageView={pageView}
                                  >
                                    <span>Remover</span>
                                  </RemoveCardFromCollectionButton>
                                </div>
                              )}

                            {collection.does_it_have_all && (
                              <RemoveCardFromCollectionButton
                                noconfirm
                                textRemove={false}
                                cards={cardsInCollection}
                                collectionId={collection.id}
                                context="Results"
                                appearance={'danger'}
                                icon={<span>x</span>}
                                loadingIcon={
                                  <SpinningLoader color={'white'} size="sm" />
                                }
                                className="h-8 w-36"
                                currentPageBulkAction={currentPageBulkAction}
                                pageView={pageView}
                              >
                                <span>Remover</span>
                              </RemoveCardFromCollectionButton>
                            )}

                            {!collection.does_it_have_all && (
                              <AddCardToCollectionButton
                                cards={cards}
                                collection={collection}
                                context="Results"
                                currentPageBulkAction={currentPageBulkAction}
                                pageView={pageView}
                              />
                            )}
                          </div>
                        </div>
                      );
                    },
                  )}
              </div>
            </div>
          )}

          {activeIndex === 2 && (
            <div className="mt-5">
              <CreateAndAddToCollection
                cards={cards}
                context="Results"
                query={query}
                handleChangeNavigation={handleChangeNavigation}
                currentPageBulkAction={currentPageBulkAction}
                pageView={pageView}
              />
            </div>
          )}
        </div>

        <DialogFooter>
          <Button size="sm" appearance="primary" onClick={clearSelectedCards}>
            Concluído
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  userCollections: state.collections.userCollections,
  requestingUserCollections: state.collections.requestingUserCollections,
  defaultCollectionSave: state.collections.defaultCollectionSave,
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, CollectionsActions, ProfilesActions),
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveCardToCollectionButton);
