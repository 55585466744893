import TuriviusSidenav from '../TuriviusSidenav';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Container, Content } from 'rsuite';
import '../../../ds-components/styles/index.jsx.css';
import 'rsuite/dist/rsuite.min.css';
import './style.scss';
import { requester } from '../../../requesters';
import { useLocation } from 'react-router-dom';

const TuriviusBasePage = ({ children }) => {
  const location = useLocation(); // Controla o o location para monitrar a url
  const [isPesquisaResultados, setIsPesquisaResultados] = useState(false);
  const [actionPermissions, setActionPermissions] = useState({});

  const checkPermission = useCallback(async () => {
    try {
      const response = await requester.segmentation.validateAction(
        'search_labor_jurimetry',
      );

      if ([400, 401, 403].includes(response.status)) {
        const newPermission = response.message;
        setActionPermissions((prev) => {
          return { search_labor_jurimetry: newPermission };
        });
      } else {
        setActionPermissions({ search_labor_jurimetry: response });
      }

      localStorage.setItem(
        'actions_permission',
        JSON.stringify({ search_labor_jurimetry: response }),
      );
    } catch {
      localStorage.setItem('actions_permission', JSON.stringify({}));
    }
  }, []);

  useEffect(() => {
    checkPermission();
  }, [checkPermission]);

  const memoizedSidenav = useMemo(
    () => <TuriviusSidenav actionPermissions={actionPermissions} />,
    [actionPermissions],
  );

  useEffect(() => {
    // EXPLANATION: Aqui ele controla o modo da página pelo pathname
    if (
      location.pathname === '/pesquisa/resultados' &&
      location.search.includes('query')
    ) {
      setIsPesquisaResultados(true);
    } else {
      setIsPesquisaResultados(false);
    }
  }, [location]);

  //EXPLANATION: Render conditional (O react-router vai passar como children dependendo da barra de busca)
  // Após isso o componente será rederizado no layout em questão.
  // Se for a página de pesquisa ele tem um layout diferente
  return (
    <Container style={{ minHeight: '100vh' }}>
      {memoizedSidenav}
      {isPesquisaResultados ? (
        <div style={{ width: '100%' }}>{children}</div>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
};

export default TuriviusBasePage;
