import { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'rsuite';
import { ResultCard } from '../../../jurisprudential-search/components/results/Cards/Result/ResultCard';
import CommentsCard from '../Collection/Comments/CommentsCard';
import '../style.scss';

const CollectionsResultCard = ({
  card,
  collectionId,
  context,
  entities,
  requestingComments,
  comments,
  addComment,
  removeComment,
  theMatchResultsThatHaveCollections,
  collectionTags,
  getCollectionComments,
}) => {
  const [isMobile, setIsMobile] = useState(window.detectMob());

  const updateDimensions = () => {
    setIsMobile(window.detectMob());
  };

  const getEntityById = (entityId) => {
    const entitiesArr = Object.values(entities);
    return entitiesArr.find((entity) => entity.id === entityId);
  };

  const formatCardsValue = () => {
    const entity = getEntityById(Number(card.entity));
    return {
      ...card,
      id: card.id,
      entity: {
        id: card.entity,
        name: entity?.initials,
        jurisdiction: entity?.jurisdiction,
      },
      name: card.nome,
      relator: {
        id: card.relator_id,
        name: card.relator,
      },
      n_processo: card.n_processo,
      n_acordao: card.n_acordao,
      dataPub: card.data_pub,
      dataJul: card.data_jul,
      orgaoJulgador: card.orgao_julgador,
      content: card.content,
      url: card.url,
      chips: card.chips.length === 0 ? null : card.chips,
      timeline: card.timeline,
    };
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="collections-result-card">
      <Grid fluid>
        <Row>
          <Col xs={24} sm={24} md={isMobile ? 24 : 18}>
            <ResultCard
              card={formatCardsValue()}
              key={card.id}
              collectionId={collectionId}
              context={context}
              theMatchResultsThatHaveCollections={
                theMatchResultsThatHaveCollections
              }
              collectionTags={collectionTags}
              pageView="collectionpage"
            />
          </Col>
          <Col xs={0} sm={0} md={isMobile ? 0 : 6} style={{ padding: 0 }}>
            <CommentsCard
              collectionId={collectionId}
              card={card}
              isMobile={isMobile}
              requestingComments={requestingComments}
              comments={comments}
              addComment={addComment}
              removeComment={removeComment}
              getCollectionComments={getCollectionComments}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default CollectionsResultCard;
