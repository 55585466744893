import { AddCircle, TickCircle } from 'iconsax-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import './style.scss';
import { useCollectionTags } from '../../../../../store/ducks/collectionTags';
import {
  PostHogSendCollection,
  PostHogSendBulkAction,
} from '../../../../../utils/PostHogSendEvents';

const ReassignTagToCardsButton = ({
  cards,
  collectionId,
  tag,
  activeTagsToCardsMap,
  currentPageBulkAction,
}) => {
  const [loading, setLoading] = useState(false);
  const { fetch: fetchCollectionTags } = useCollectionTags();

  function reassignTagToCardsHandler() {
    setLoading(true);
    const cardIds = cards.map((card) => card.id);

    let removePromises = [];
    activeTagsToCardsMap.forEach((item) => {
      removePromises.push(
        requester.collections.removeCardsToTag(item.tagId, item.cardIds),
      );
    });

    Promise.all(removePromises)
      .then(() => {
        PostHogSendCollection('add_remove_maker_collection', null, 'add'); // POSTHOG_NEW
        if (currentPageBulkAction == 'collectionpage') {
          PostHogSendBulkAction('add_remove_maker_bulkaction', null, 'add'); // POSTHOG_NEW
        }
        requester.collections.addCardsToTag(tag.id, cardIds).then(() => {
          fetchCollectionTags(collectionId);
          toast.success(
            cards.length === 1
              ? 'Marcador reatribuído'
              : 'Marcadores reatribuídos',
            {
              icon: <TickCircle size="sm" color="#07bc0c" />,
              className: 'bottom-[50px]',
            },
          );
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Button
      fullWidth
      onClick={reassignTagToCardsHandler}
      appearance="secondary"
      icon={
        loading ? (
          <SpinningLoader size="sm" color="gray" />
        ) : (
          <AddCircle size={16} color="#475467" />
        )
      }
      size="sm"
    >
      {'Reatribuir'}
    </Button>
  );
};

export default ReassignTagToCardsButton;
