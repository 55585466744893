import { request } from './requester';

function getServicePlans(service) {
  return request({
    type: 'GET',
    microservice: 'segmentation',
    action: `plans/${service}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function validateAction(action) {
  return request({
    type: 'GET',
    microservice: 'segmentation',
    action: `validate_user_action?action=${action}`,
    auth: 'token',
  })
    .then((response) => {
      return response;
    })
    .catch((response) => {
      if ([400, 401, 403].includes(response.status)) {
        return response;
      }
    });
}

function sendEvent(event_data) {
  return request({
    type: 'POST',
    microservice: 'segmentation',
    action: `log_event`,
    auth: 'token',
    data: event_data,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      err;
    });
}

function sendMessageUpgrade(service_data) {
  return request({
    type: 'POST',
    microservice: 'segmentation',
    action: `send_upgrade_message`,
    auth: 'token',
    data: service_data,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      err;
    });
}

function getServicePlan(service, planAlias) {
  return request({
    type: 'GET',
    microservice: 'segmentation',
    action: `plans/${service}/${planAlias}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function createUserServiceSegmentation(
  service,
  userId,
  plano_alias,
  {
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  },
) {
  let data = {
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    plano_alias,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  };
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );

  return request({
    type: 'POST',
    microservice: 'segmentation',
    action: `admin/${service}`,
    auth: 'token',
    data: {
      user_id: userId,
      ...data,
    },
  }).then((usersPermissions) => {
    return usersPermissions;
  });
}

function updateUserServiceSegmentation(
  service,
  userId,
  {
    plano_alias,
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  },
) {
  let data = {
    user_id: userId,
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    plano_alias,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  };

  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );

  return request({
    type: 'PATCH',
    microservice: 'segmentation',
    action: `admin/${service}`,
    auth: 'token',
    data,
  }).then((usersPermissions) => {
    return usersPermissions;
  });
}

function deleteServiceSegmentationByUserId(service, userId) {
  return request({
    type: 'DELETE',
    microservice: 'segmentation',
    action: `admin/${service}/${userId}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

export const segmentation = {
  getServicePlans,
  getServicePlan,
  createUserServiceSegmentation,
  updateUserServiceSegmentation,
  deleteServiceSegmentationByUserId,
  validateAction,
  sendEvent,
  sendMessageUpgrade,
};
