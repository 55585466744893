import { createActions, createReducer } from 'reduxsauce';

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setJurimetric: ['id', 'jurimetric'],
});

const INITIAL_STATE = {
  jurimetrics: {},
  validEntities: [
    0, 1, 2, 6, 7, 23, 24, 25, 27, 28, 30, 42, 43, 63, 69, 71, 97,
  ],
};

const setJurimetric = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    jurimetrics: { ...state.jurimetrics, [action.id]: action.jurimetric },
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.SET_JURIMETRIC]: setJurimetric,
});
