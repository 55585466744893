import React from 'react';
import { useState } from 'react';
import { Modal } from 'rsuite';
import { Button as TuringButton } from '../../../../ds-components';
import { Trash } from 'iconsax-react';

import { PostHogSendMonitoring } from '../../../../utils/PostHogSendEvents';

const DeleteModalMonitoring = ({ monitoring, onDeleteMonitoring }) => {
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(!modal);
  };

  const deleteMonitoring = () => {
    onDeleteMonitoring(monitoring.id, monitoring.query);
    PostHogSendMonitoring('delete_monitoring'); // POSTHOG_NEW
    handleModal();
  };

  return (
    <div>
      <TuringButton
        appearance="danger"
        outline
        size="sm"
        onClick={handleModal}
        icon={<Trash color="#f04438" size={16} />}
        className="monitoring-delete-btn"
        fullWidth
      >
        Deletar
      </TuringButton>
      <Modal open={modal} onClose={handleModal}>
        <Modal.Title>Deletar Monitomanto</Modal.Title>
        <Modal.Body>
          <p>
            Tem certeza que deseja parar de receber notificações de{' '}
            <b>{monitoring.query}</b>? Essa ação não poderá ser desfeita.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content_between">
          <TuringButton onClick={handleModal} appearance="minimal" size="sm">
            Cancelar
          </TuringButton>
          <TuringButton
            onClick={deleteMonitoring}
            appearance="danger"
            size="sm"
          >
            Deletar
          </TuringButton>
          {/* <Button onClick={handleModal}>Cancelar</Button>
          <Button onClick={deleteMonitoring} className="menu-button_delete">
            Deletar
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteModalMonitoring;
