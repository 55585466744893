import { useState } from 'react';
import { Modal, Message, useToaster } from 'rsuite';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requester } from '../../../../../requesters';
import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { ArrowLeft2, Briefcase, CloseCircle, Share } from 'iconsax-react';
import './ShareCollectionWithCompanyButton.scss';

import { PostHogSendCollection } from '../../../../../utils/PostHogSendEvents';

const ShareCollectionWithCompanyButton = ({
  publicToCompany,
  collectionTitle,
  collectionId,
  context,
  onSearch,
  TabOrInside,
}) => {
  const toaster = useToaster();
  const [modal, setModal] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const handleModal = () => setModal(!modal);

  const shareWithAllCompany = () => {
    setRequesting(true);

    requester.collections
      .shareWithCompany(
        localStorage.getItem('user'),
        collectionId,
        !publicToCompany,
        context,
      )
      .then((response) => {
        if (publicToCompany == false) {
          PostHogSendCollection('shared_collection', TabOrInside, 'office'); // POSTHOG_NEW
        }
        onSearch();
        toaster.push(
          <Message
            header={` Dados ${
              publicToCompany ? 'descompartilhados' : 'compartilhados'
            }`}
            type="success"
            showIcon
            closable
          >
            {`Coleção ${
              publicToCompany ? 'descompartilhada' : 'compartilhada'
            } com sucesso`}
          </Message>,
          { duration: 5000 },
        );

        handleModal();
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message header="Ocorreu um erro" type="error" showIcon closable>
            Houve um problema de conexão, tente novamente
          </Message>,
          { duration: 4000 },
        );
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  return (
    <>
      <Button
        fullWidth
        size="sm"
        appearance="minimal"
        icon={<Briefcase size={24} color=" #475467" />}
        onClick={handleModal}
      >
        {publicToCompany
          ? 'Descompartilhar com Escritório'
          : 'Compartilhar com Escritório'}
      </Button>

      <Modal className="tv-modal-default" open={modal} onClose={handleModal}>
        <Modal.Header closeButton={false} className="tv-modal-default_header">
          <div style={{ textAlign: 'end' }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <h6 className="tv-modal-default_header_title">
            Compartilhar com escritório
          </h6>
        </Modal.Header>
        <Modal.Body className="tv-modal-default_body">
          <p className="tv-modal-default_header_subtitle">
            Tem certeza que deseja compartilhar a coleção{' '}
            <b>{collectionTitle}</b> com todo o escritório?
          </p>
        </Modal.Body>
        <Modal.Footer className="tv-modal-default_footer">
          <Button
            size="sm"
            fullWidth
            icon={<ArrowLeft2 />}
            appearance="secondary"
            onClick={handleModal}
          >
            Cancelar
          </Button>
          <Button
            size="sm"
            fullWidth
            icon={<Share />}
            onClick={shareWithAllCompany}
          >
            {requesting ? (
              <SpinningLoader color="white" size="sm" />
            ) : publicToCompany ? (
              'Descompartilhar'
            ) : (
              'Compartilhar'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProfilesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ShareCollectionWithCompanyButton));
