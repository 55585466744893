import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Popover, Whisper, Input, Message, toaster } from 'rsuite';
import OperatorsHelper from '../OperatorsHelper';
import { requester } from '../../../../../requesters';
import { SearchNormal1, Clock, People, User } from 'iconsax-react';
import OperatorsHelperOpen from '../OperatorsHelperOpen';

const SEARCH_MODE = {
  NORMAL: 'normal',
  PROCESS_NUMBER: 'nproc',
};

const performSearch = (searchTerm) => {
  if (searchTerm.length >= 3) {
    document.getElementById('btn-search').click();
  }
};

// EXPLANATION: Esse elemento é usado para receber o array qye tem [<span>, 'texto'] e montar um único elemento
// Isso é necessário para que ao renderizar a opção lá na barra de sugestões ele não corte os termos destacados do texto original.
// Se você não usar isso ele pode vir a cortar o texto e gerar um um array que é redereziado com espaaços
// e.g = (3) ['', {…}, 'ausência de processo administrativo']
const CombinedText = ({ parts }) => {
  return (
    <span>
      {parts.map((item, index) =>
        typeof item === 'string' ? (
          item
        ) : (
          <React.Fragment key={index}>{item}</React.Fragment>
        ),
      )}
    </span>
  );
};

// Essa função gerar as opções com highligth. Talvez fosse melhor gerar no backend isso
const highlightText = (text, searchTerm) => {
  if (!searchTerm.trim()) return text;

  const escapedSearch = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapa caracteres especiais

  const regex = new RegExp(`(${escapedSearch})`, 'gi'); // Não precisa remover os espaço

  const finalText = text.split(regex).map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={index} className="highlighted-text">
        {part}
      </span>
    ) : (
      part
    ),
  );

  return <CombinedText parts={finalText} />; // usado para renderizar as opções sem espaço
};

const OptionListcollectionHistoryOptions = ({
  options,
  setQuery,
  selectedIndex,
  setSelectedIndex,
  query,
}) => {
  return (
    <>
      {options.map((option, index) => {
        const Icon =
          option._source.kind === 'collection_history' ? Clock : SearchNormal1; // EXPLANATION: Aqui ele diz qual ícone irá aparecer

        const isSelected = index === selectedIndex;

        return (
          <div
            key={option._id || index}
            style={{
              padding: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            className={`option-item ${option._source.kind} ${isSelected ? 'selected' : ''}`}
            onClick={() => {
              setSelectedIndex(index);
              setQuery(option.text);
            }}
          >
            <Icon size="24" />
            {highlightText(option.text, query)}
          </div>
        );
      })}
    </>
  );
};

const OptionListotherOptions = ({
  options,
  setQuery,
  selectedIndex,
  setSelectedIndex,
  historyLength,
  query,
}) => {
  return (
    <>
      {options.map((option, index) => {
        const Icon =
          option?._source?.kind === 'collection_history'
            ? Clock
            : SearchNormal1; // EXPLANATION: Aqui ele diz qual ícone irá aparecer

        const isSelected = index + historyLength === selectedIndex;

        return (
          <div
            key={option._id || index}
            style={{
              padding: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            className={`option-item ${option._source.kind} ${isSelected ? 'selected' : ''}`}
            onClick={() => {
              setSelectedIndex(index + historyLength);
              setQuery(option.text);
            }}
          >
            <Icon size="20" />
            {highlightText(option.text, query)}
          </div>
        );
      })}
    </>
  );
};

const SearchInput = ({ queryError, setQuery, query, placeholder }) => {
  const [options, setOptions] = useState([]);
  const searchHistoryRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [initialQuery, setInitialQuery] = useState('');
  const userId = localStorage.getItem('user') || 0;
  const companyId = localStorage.getItem('company') || 0;
  const [width, setWidth] = useState(0);

  const [isWhisperOpen, setIsWhisperOpen] = useState(true);

  // Para controlar o debounce
  const debounceTimerRef = useRef(null);

  useEffect(() => {
    setQuery('');
  }, [placeholder, setQuery]);

  const getAcSuggestion = (searchValue) => {
    // Se query estiver vazia, limpa as opções
    if (!searchValue.trim()) {
      setOptions([]);
      return;
    }

    // Captura o valor que está sendo buscado localmente
    const localValue = searchValue;

    requester.cards
      .responseSeacrhInput({
        query: localValue,
        userId: userId,
        companyId: companyId,
      })
      .then((response) => {
        // Verifica se o que retornou ainda corresponde à última query digitada
        // Se for diferente, ignoramos esse resultado.
        // if (localValue === query) {
        setOptions(Array.isArray(response) ? response : []);
        // }
      })
      .catch((err) => {
        console.error(err);
        // Mesmo tratamento: só limpa as opções se ainda for a query atual
        if (localValue === query) {
          setOptions([]);
        }
      });
  };

  const handleChangeQuery = (value) => {
    setQuery(value);
    setIsWhisperOpen(true); // Mantém o Whisper aberto mesmo ao pressionar Esc
    setSelectedIndex(-1);

    // Se já tiver um timeout pendente, limpa
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Se apertou espaço no final, busca imediatamente
    if (value.endsWith(' ')) {
      getAcSuggestion(value);
    } else {
      // Caso contrário, aguarda 500ms depois que o usuário parar de digitar
      debounceTimerRef.current = setTimeout(() => {
        getAcSuggestion(value);
      }, 300);
    }
  };

  const addOperator = (operator) => {
    const inputElement = document.getElementById('home-page-search-input');
    const cursorPosition = inputElement.selectionStart;
    let newQuery = query;

    newQuery =
      operator === '$'
        ? `${newQuery.substring(0, cursorPosition)}${operator}${newQuery.substring(cursorPosition)}`
        : `${newQuery.substring(0, cursorPosition)} ${operator} ${newQuery.substring(cursorPosition)}`;

    newQuery = newQuery.replace('  ', ' ');
    setQuery(newQuery);
    inputElement.focus(); // Focus de volta no input
    setTimeout(() => {
      inputElement.setSelectionRange(
        cursorPosition + operator.length + 1,
        cursorPosition + operator.length + 1,
      );
    }, 200);
  };

  // Essa função controla o highlight das opções
  const handleKeyDown = (e) => {
    try {
      setIsWhisperOpen(true); // Mantém o Whisper aberto mesmo ao pressionar Esc
      if (e.key === 'ArrowDown') {
        e.preventDefault();

        if (selectedIndex === -1) {
          setInitialQuery(query);
        }

        setSelectedIndex((prevIndex) => {
          const nextIndex = prevIndex < options.length - 1 ? prevIndex + 1 : 0;
          if (options[nextIndex]) {
            setQuery(options[nextIndex].text);
          }
          return nextIndex;
        });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();

        if (options.length === 0 || selectedIndex === -1) {
          return;
        }

        setSelectedIndex((prevIndex) => {
          const nextIndex = prevIndex > 0 ? prevIndex - 1 : -1;
          if (nextIndex === -1) {
            setQuery(initialQuery);
          } else if (options[nextIndex]) {
            setQuery(options[nextIndex].text);
          }
          return nextIndex;
        });
      } else if (e.key === 'Enter') {
        if (selectedIndex >= 0 && selectedIndex < options.length) {
          setQuery(options[selectedIndex].text);
        } else {
          setQuery(query);
        }

        const searchTerm = query.trim();
        if (searchTerm.length >= 3) {
          document.getElementById('btn-search').click();
        } else {
          toaster.push(
            <Message
              style={{ marginBottom: '50px' }}
              duration={4000}
              type="warning"
              showIcon
              closable
            >
              Sua busca deve ter pelo menos três caracteres
            </Message>,
            { placement: 'topEnd' },
          );
        }
        setOptions([]);
        setSelectedIndex(-1);
      }
    } catch (error) {
      console.error('Erro no handleKeyDown: Linha 174 Sinput');
    }
  };

  const collectionHistoryOptions = options?.filter(
    (option) => option._source.kind === 'collection_history',
  );
  const otherOptions = options.filter(
    (option) => option._source.kind !== 'collection_history',
  );

  // Atualiza a largura do componente pai
  useLayoutEffect(() => {
    const updateWidth = () => {
      const parentWidth =
        document.getElementById('search-card-query-col')?.offsetWidth || 0;
      setWidth(parentWidth);
    };

    updateWidth(); // Atualiza no início

    // Observer para detectar mudanças de tamanho dinamicamente
    const observer = new ResizeObserver(() => {
      updateWidth();
    });

    const searchCardQueryCol = document.getElementById('search-card-query-col');
    if (searchCardQueryCol) {
      observer.observe(searchCardQueryCol);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const hasSearchResults = options.length > 0;
  const home = true;
  const placeholderValue =
    placeholder === SEARCH_MODE.NORMAL
      ? 'Quais termos deseja buscar?'
      : 'Digite o número do processo ou do acórdão';
  const containerRef = useRef(null);
  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Whisper
        ref={searchHistoryRef}
        placement="bottom"
        // trigger="active"
        trigger="active" // Mantém ativo
        open={isWhisperOpen}
        onOpen={() => setIsWhisperOpen(true)}
        onClose={() => setIsWhisperOpen(false)}
        container={containerRef.current}
        speaker={
          placeholder === SEARCH_MODE.NORMAL ? (
            <Popover
              container={null}
              id="search-history-popover"
              style={{
                width: `${width}px`, // Atualiza dinamicamente com base no estado
                // width: document.getElementById('search-card-query-col')
                // ?.offsetWidth, // EXPLANATION: Isso aqui está controlando o tamanho do elemento, mas só quando ele ativa e desativa problema
                left: '0 !important',
                // fazer o popover aparecer abaixo e atrás da barra de busca
                transform: 'translateY(-3.5px) translateX(-1px)', // Move o Popover 3px para dentro no topo
                zIndex: -1,
                borderTopLeftRadius: '0x',
                borderTopRightRadius: '0px',
                borderRadius: '0px 0px 8px 8px',

                border: '1px solid #F0F0F0',
                boxShadow:
                  '0px 1px 3px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)',

                borderTop: '0px solid',
              }}
            >
              <div className="search-result" style={{ marginTop: '8px' }}>
                <OptionListcollectionHistoryOptions
                  options={collectionHistoryOptions}
                  setQuery={setQuery}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  query={query}
                />
              </div>
              {collectionHistoryOptions.length > 0 &&
                otherOptions.length > 0 && (
                  <div
                    style={{
                      padding: '4px',
                      color: '#98A2B3',
                      marginLeft: '4px',
                      fontSize: '10px',
                      marginTop: '12px', // Margens do autocomplete
                    }}
                  >
                    As pessoas também buscam por
                  </div>
                )}
              <div className="search-result">
                <OptionListotherOptions
                  options={otherOptions}
                  setQuery={setQuery}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  // EXPLANATION: Se tiver sugestões de histórico ele passa para esse componente que irá posicionar as queries de usuário com index a mais
                  historyLength={collectionHistoryOptions.length}
                  query={query}
                />
              </div>
              <div style={{ marginTop: '23px', marginBottom: '23px' }}>
                <hr />
              </div>
              <OperatorsHelperOpen
                addOperator={addOperator}
                home={home}
                hasSearchResults={hasSearchResults}
                top={options?.length > 0 ? false : true}
                currentPage={'homepage'}
              />
            </Popover>
          ) : (
            <div></div>
          )
        }
      >
        <Input
          id="home-page-search-input"
          data-testid="simple-home-page-search-input"
          className={`rs-custom-input-big ${queryError ? 'rs-custom-input-error' : ''}`}
          value={query}
          onChange={handleChangeQuery}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          placeholder={placeholderValue}
        />
      </Whisper>
    </div>
  );
};

export default SearchInput;
