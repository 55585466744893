import { Input } from 'rsuite';
import OperatorsHelper from '../../../jurisprudential-search/components/search/OperatorsHelper';
import { useRef } from 'react';

const TermLabelMonitoring = ({ term, setTerm, termError }) => {
  const inputRef = useRef(null);

  const addOperator = (operator) => {
    let query = `${term} ${operator} `.replace('  ', ' ');
    setTerm(query);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={`monitoring-settings-content ${termError ? 'error' : null}`}
    >
      <label className="monitoring-settings-label">Termo</label>
      <Input
        id="term-monitoring"
        className="term-monitoring"
        placeholder="Qual termo deseja monitorar?"
        onChange={setTerm}
        value={term}
        inputRef={inputRef}
      />
      <OperatorsHelper pageCurrent={'monitoring'} addOperator={addOperator} />
    </div>
  );
};

export default TermLabelMonitoring;
