import { ArrowRight } from 'iconsax-react';
import './style.scss';
import { PostHogSendHomePage } from '../../../../../utils/PostHogSendEvents';

const OperatorCard = ({ operator, addOperator, currentPage }) => {
  const sendEventPostHog = () => {
    if (currentPage == 'homepage') {
      let operatorSelect =
        operator.name == 'E'
          ? 'e'
          : operator.name == 'OU'
            ? 'ou'
            : operator.name == 'NÃO'
              ? 'nao'
              : operator.name == '‘’   ‘’'
                ? 'aspas'
                : 'adj';
      PostHogSendHomePage('operator_video', operatorSelect); // POSTHOG_NEW
    }
  };

  return (
    <div className="operators-card">
      <div className="operators-card_header">
        <span className="font-semibold">Operador</span>
        <div
          className={`operators-btn-item operators-btn-color-${operator.operatorColor}`}
          data-testid={operator.dataTestId}
        >
          {operator.name}
        </div>
      </div>
      <div className="operators-card_content">{operator.description}</div>
      <div className="operators-card_footer">
        <a
          href={operator.videoRoute}
          target="_blank"
          rel="noreferrer"
          onClick={sendEventPostHog}
        >
          Veja o vídeo aqui
        </a>
        <ArrowRight size={16} color="#053AC8" />
      </div>
    </div>
  );
};

export default OperatorCard;
