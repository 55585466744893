import posthog from 'posthog-js';

const POSTHOG_ENABLE = process.env.REACT_APP_POSTHOG_SEND_ENVENTS === 'true';

// Evento de Pesquisa
export const PostHogSendSearch = (action, origin) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;
    let type =
      localStorage.getItem('search_mode') == 'normal'
        ? 'busca_normal'
        : 'busca_numero';
    posthog.capture('SearchsEvent', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      origin: origin,
      type: type,
    });
  }
};

// Evento de Coleções
export const PostHogSendCollection = (
  action,
  origin,
  type,
  batch,
  branding,
) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('CollectionPageEvent', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      origin: origin,
      type: type,
      batch: batch,
      branding: branding,
    });
  }
};

// Evento de Monitoramento
export const PostHogSendMonitoring = (
  action,
  origin,
  type,
  active,
  selected_administrative_bodies,
  selected_federal_court,
  selected_state_court,
  selected_labor_court,
  selected_high_court,
  with_all,
) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('MonitoringsEvent', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      origin: origin,
      type: type,
      active: active,
      selected_administrative_bodies: selected_administrative_bodies,
      selected_federal_court: selected_federal_court,
      selected_state_court: selected_state_court,
      selected_labor_court: selected_labor_court,
      selected_high_court: selected_high_court,
      with_all: with_all,
    });
  }
};

// Evento da Pagina de Resultado (ResultPage)
export const PostHogSendResultPage = (action, origin, type) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('ResultPage', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      origin: origin,
      type: type,
    });
  }
};

// Evento dos Cards em Resultados (ResultsCard)
export const PostHogSendResultsCard = (action, origin, feedback) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('ResultsCard', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      origin: origin,
      feedback: feedback,
    });
  }
};

// Evento da Home (HomePage)
export const PostHogSendHomePage = (action, type) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('HomePage', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      type: type,
    });
  }
};

// Evento do Menu
export const PostHogSendMenu = (option) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('Menu', {
      action: 'menu_options',
      userID: userID,
      CompanyID: CompanyID,
      option: option,
    });
  }
};

// Evento do Filtro
export const PostHogSendFilter = (
  action,
  origin,
  button,
  include_similar_terms,
  selected_all_bodies,
  selected_administrative_bodies,
  chose_higher_court,
  selected_federal_court,
  selected_state_court,
  selected_labor_court,
  date,
  selected_result_decision,
  selected_types_decision,
  selected_session,
) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('FiltersEvent', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      origin: origin,
      button: button,
      include_similar_terms: include_similar_terms,
      selected_all_bodies: selected_all_bodies,
      selected_administrative_bodies: selected_administrative_bodies,
      chose_higher_court: chose_higher_court,
      selected_federal_court: selected_federal_court,
      selected_state_court: selected_state_court,
      selected_labor_court: selected_labor_court,
      date: date,
      selected_result_decision: selected_result_decision,
      selected_types_decision: selected_types_decision,
      selected_session: selected_session,
    });
  }
};

// Evento de Jurimetria Trabalhista
export const PostHogSendLaborJurimetry = (option, cnpj) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = true;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('LaborJurimetryEvent', {
      action: 'search_labor_jurimetry',
      userID: userID,
      CompanyID: CompanyID,
      option: option,
      cnpj: cnpj,
    });
  }
};

// Evento de Header
export const PostHogSendHeader = (action, option) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('Header', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      option: option,
    });
  }
};

// Evento de Login (Quando o Login for bem sucedido.)
export const PostHogSendLogin = () => {
  if (!POSTHOG_ENABLE) return;

  // Pega o UserID eo CompanyID
  // const userID = JSON.parse(localStorage.getItem('profile')).id;
  // const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;   - Pela logica nao teria id nem nada mas mandei como atributo caso peça para fazer um esquema de pegar o id eo email

  const LaborJurimetry = false;

  if (LaborJurimetry) {
    posthog.capture('LoginEvent', {
      action: 'login',
      // userID: userID,
      // CompanyID: CompanyID,
    });
  }
};

// Evento do BulkAction
export const PostHogSendBulkAction = (action, origin, type) => {
  if (!POSTHOG_ENABLE) return;
  const LaborJurimetry = false;

  if (LaborJurimetry) {
    // Pega o UserID eo CompanyID
    const userID = JSON.parse(localStorage.getItem('profile')).id;
    const CompanyID = JSON.parse(localStorage.getItem('profile')).company_id;

    posthog.capture('BulkActionEvent', {
      action: action,
      userID: userID,
      CompanyID: CompanyID,
      origin: origin,
      type: type,
    });
  }
};
