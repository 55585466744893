import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, Progress, Animation } from 'rsuite';
import { requester } from '../../../requesters';
import vars from '../../../vars.module.scss';
import Confetti from 'react-dom-confetti';

import { PostHogSendHeader } from '../../../utils/PostHogSendEvents';

const config = {
  angle: 120,
  spread: 45,
  startVelocity: 45,
  elementCount: 100,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: '4px',
  height: '4px',
  colors: [
    vars.turivius,
    vars.secondary,
    vars.turivius_light,
    vars.secondary_light,
    vars.turivius_dark,
    vars.secondary_dark,
  ],
};

const ProfileProgress = ({ profile, ...props }) => {
  const [progress, setProgress] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [explode, setExplode] = useState(false);

  function getProfileUser() {
    const storageSetupProgress = localStorage.getItem('hideSetupProgress');
    requester.profiles
      .getOnboardingUserById(profile.id)
      .then((res) => {
        setProgress(Math.round(res.profile_progress));
        if (!storageSetupProgress) {
          hideSetupProgress(res.profile_progress);
        }
      })
      .catch(() => console.log);
  }

  function hideSetupProgress(profileProgress) {
    if (profileProgress === 100) {
      setExplode(true);

      setTimeout(() => {
        setShowProgress(false);
      }, 3000);

      localStorage.setItem('hideSetupProgress', true);
    }
  }

  useEffect(() => {
    const storageSetupProgress = localStorage.getItem('hideSetupProgress');
    setShowProgress(!storageSetupProgress);

    getProfileUser();
  }, []);

  return (
    <Animation.Collapse in={showProgress}>
      <div className="menu-profile-progress">
        <label>Configuração do seu perfil</label>
        <div className="menu-profile-progress_info">
          <span>{progress == 100 ? 'Concluído' : 'Andamento'}</span>
          {progress ? <span>{progress}%</span> : <Loader size="xs" />}
        </div>

        <Progress.Line
          status={'active'}
          percent={progress}
          showInfo={false}
          strokeColor={progress == 100 ? '#12b76a' : '#0069F5'}
        />
        <a
          onClick={() => {
            props.history.push('/setup');
            PostHogSendHeader('perfil_options', 'config_conta'); // POSTHOG_NEW
          }}
        >
          Clique aqui e conclua seu perfil
        </a>
        <Confetti active={explode} config={config} />
      </div>
    </Animation.Collapse>
  );
};

export default withRouter(ProfileProgress);
