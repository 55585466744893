import React from 'react';
import { Loader, TagPicker } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

class RelatorMultipleAutocompleteFilter extends React.Component {
  state = {
    requesting: false,
    items: null,
    autocompleteFilter: [],
    value: [],
  };

  componentDidUpdate() {
    const { filter } = this.props;

    const externalFilter =
      this.props.filtersProps.externalFilters &&
      filter &&
      this.props.filtersProps.externalFilters[filter.field_name];
    const { items } = this.state;

    if (
      this.mounted &&
      externalFilter &&
      items &&
      externalFilter.length !== items.length
    ) {
      this.setState({
        items: externalFilter?.map((filter) => {
          return {
            ...filter,
            id: filter.key,
          };
        }),
      });
    }
  }

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.setState({
        items: this.props.filtersProps.externalFilters[
          this.props.filter.field_name
        ]?.map((filter) => {
          return {
            ...filter,
            id: filter.key,
          };
        }),
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { items, requesting, value } = this.state;
    const { filter, placeholder } = this.props;
    const { setTmpFilters } = this.props.filtersProps;
    return (
      <div
        style={{
          marginLeft: 0,
          marginBottom: 4,
          marginTop: 13,
        }}
      >
        <label
          id="label-relator"
          className="rs-custom-input-label"
          htmlFor={`rs-select-${filter.field_name}`}
          style={{
            textTransform: 'uppercase',
            fontSize: 11,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <b>{filter.public_name}</b>
        </label>
        {items !== undefined && items !== null && !requesting ? (
          <TagPicker
            virtualized
            className={`rs-custom-picker-toggle-wrapper-big`}
            id={`rs-select-${filter.field_name}`}
            labelKey={'key'}
            valueKey={'related_names'}
            data={items}
            style={{ width: '100%' }}
            placeholder={placeholder}
            cleanable={false}
            placement={'top'}
            value={value?.map((v) => (typeof v === 'string' ? v : v['key']))}
            onChange={(valor, event) => {
              this.setState({ value: valor });

              if (this.props.context) {
                posthog.capture(this.props.context, {
                  action: 'change_filter',
                  id: filter.field_name,
                });
                postHubSpotEvent('app_changefilters', {
                  app_changefilters: this.props.context,
                });
              }

              if (valor.length > 0) {
                setTmpFilters(filter.field_name, [
                  ...valor.join(',').split(','),
                ]);
              } else {
                setTmpFilters(filter.field_name, [...valor]);
              }
            }}
            container={() => {
              const container = document.getElementById('label-relator');
              return container;
            }}
            renderMenuItem={(label, item) => {
              if (items.length > 0) {
                return (
                  <div>
                    {label} ({item.doc_count})
                  </div>
                );
              } else {
                <Loader />;
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default RelatorMultipleAutocompleteFilter;
