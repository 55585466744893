const CustomerXTracking = (mapping, event) => {
  try {
    const profile = localStorage.getItem('profile');
    const { company_id, email } = JSON.parse(profile);

    if (!document.getElementById('cx-tracking-script')) {
      const script = document.createElement('script');
      script.id = 'cx-tracking-script';
      script.innerHTML = `cx.tracking('currentUser', '${company_id}', '${email}');`;
      document.body.appendChild(script);
    }
    cx.tracking('send', `${mapping}`, `${event}`);
  } catch {
    console.log('eigns problems');
  }
};

export default CustomerXTracking;
