import { Add, TickCircle } from 'iconsax-react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { Message, toaster } from 'rsuite';
import redCrossIcon from '../../../../../assets/svgs/red-cross.svg';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import { useCollections } from '../../../../../store/ducks/theCollections';
import SeeCollectionMessage from '../BulkActionBar/SeeCollectionMessage';
import { useDispatch, useSelector } from 'react-redux';
import { Types as BulkactionTypes } from '../../../../../store/ducks/collectionsBulkaction';

import {
  PostHogSendCollection,
  PostHogSendBulkAction,
} from '../../../../../utils/PostHogSendEvents';

const AddCardToCollectionButton = (props) => {
  const { fetchCollectionsByCards } = useCollections();
  const [state, setState] = useState({
    loading: false,
  });

  const verifyAndGetCompanyProfiles = () => {
    const {
      companiesProfiles,
      fetchingCompaniesProfiles,
      toggleFetchingCompanyProfiles,
      setCompanyProfiles,
    } = props;
    const company = localStorage.getItem('company');
    if (!companiesProfiles[company] && !fetchingCompaniesProfiles[company]) {
      toggleFetchingCompanyProfiles({ [company]: true });
      requester.profiles
        .getCompanyUserNames(company, '')
        .then((response) => {
          setCompanyProfiles({ [company]: response });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => toggleFetchingCompanyProfiles({ [company]: false }));
    }
  };

  const addCardToCollectionHandler = (cards, collectionId) => {
    const cardIds = cards?.map((card) => card.id);
    setState({
      loading: true,
    });
    requester.collections
      .addCardsToCollection(
        localStorage.getItem('user'),
        collectionId,
        cardIds,
        props.context,
      )
      .then(() => {
        if (
          props.currentPageBulkAction == 'collectionpage' ||
          props.pageView == 'collectionpage'
        ) {
          PostHogSendCollection(
            'add_remove_decision_collection',
            'collectionpage',
            'add',
            cards.length === 1 ? false : true,
          ); // POSTHOG_NEW
          if (props.currentPageBulkAction == 'collectionpage') {
            PostHogSendBulkAction(
              'add_remove_decision_bulkaction',
              props.currentPageBulkAction,
              'add',
            ); // POSTHOG_NEW
          }
        } else if (
          props.currentPageBulkAction == 'resultpage' ||
          props.pageView == 'resultpage'
        ) {
          PostHogSendCollection(
            'add_remove_decision_collection',
            'resultpage',
            'add',
            cards.length === 1 ? false : true,
          ); // POSTHOG_NEW
          if (props.currentPageBulkAction == 'resultpage') {
            PostHogSendBulkAction(
              'add_remove_decision_bulkaction',
              props.currentPageBulkAction,
              'add',
            ); // POSTHOG_NEW
          }
        } else if (
          props.currentPageBulkAction == 'monitoringpage' ||
          props.pageView == 'monitoringpage'
        ) {
          PostHogSendCollection(
            'add_remove_decision_collection',
            'monitoringpage',
            'add',
            cards.length === 1 ? false : true,
          ); // POSTHOG_NEW
          if (props.currentPageBulkAction == 'monitoringpage') {
            PostHogSendBulkAction(
              'add_remove_decision_bulkaction',
              props.currentPageBulkAction,
              'add',
            ); // POSTHOG_NEW
          }
        }

        fetchCollectionsByCards(cardIds);
        postHubSpotEvent('app_addcardtocollection', {
          app_cardids: cardIds,
          app_colecaoid: collectionId,
        });
        if (props.companiesProfiles[localStorage.getItem('company')]) {
        } else {
          verifyAndGetCompanyProfiles();
        }
        toast.success(
          <SeeCollectionMessage
            collectionId={collectionId}
            notificationContent={
              cardIds.length === 1 ? 'Adicionado' : 'Adicionados' + ' a coleção'
            }
          />,
          {
            icon: <TickCircle size="sm" color="#12B76A" />,
            className: 'w-[390px] right-[77px] cursor-pointer bottom-[50px]',
            progressStyle: { background: '#12B76A' },
            closeButton: false,
          },
        );
      })
      .catch((err) => {
        if (err.message.error === 'COLLECTION_SIZE_EXCEEDED')
          toast.error(
            'Você não pode adicionar mais que 300 decisões a sua coleção.',
            {
              icon: <img src={redCrossIcon} style={{ width: '16px' }} />,
            },
          );
      })
      .finally(() => {
        setState({
          loading: false,
        });
        clearSelectedCards();
      });
  };

  const dispatch = useDispatch();

  const selectedCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );

  const clearSelectedCardsHandler = (dispatch) => {
    dispatch({ type: BulkactionTypes.UNSELECT_ALL });
  };

  const clearSelectedCards = () => {
    clearSelectedCardsHandler(dispatch, selectedCards);
  };

  const { cards, collection } = props;
  const { loading } = state;

  return (
    <Button
      size="sm"
      className="h-8 w-36"
      appearance="secondary"
      icon={
        loading ? (
          <SpinningLoader size="sm" color="gray" />
        ) : (
          <img
            src="/add.png"
            alt="icone"
            style={{ width: '12px', height: '12px' }}
          />
        )
      }
      onClick={() => {
        addCardToCollectionHandler(cards, collection.id);
      }}
    >
      Adicionar
    </Button>
  );
};

const mapStateToProps = (state) => ({
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, ProfilesActions), dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCardToCollectionButton);
