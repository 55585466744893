import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { operators } from '../../../../../config/operadores';
import OperatorCard from '../OperatorsHelperOpen/OperatorCard';
import './style.scss';

import { PostHogSendMonitoring } from '../../../../../utils/PostHogSendEvents';

const OperatorsHelperOpen = ({
  addOperator = () => {},
  hasSearchResults = false,
  hasSearchBarResults = false,
  top,
  pageCurrent,
}) => {
  const [hoveredOperator, setHoveredOperator] = useState(null);

  const handleMouseEnter = (operator) => {
    setHoveredOperator(operator);
  };

  const handleMouseLeave = () => {
    setHoveredOperator(null);
  };

  const handleButtonClick = (operator) => {
    if (pageCurrent == 'monitoring') {
      if (operator.name == 'E') {
        PostHogSendMonitoring('used_operator_monitoring', null, 'e'); // POSTHOG_NEW
      } else if (operator.name == 'OU') {
        PostHogSendMonitoring('used_operator_monitoring', null, 'ou'); // POSTHOG_NEW
      } else if (operator.name == 'NÃO') {
        PostHogSendMonitoring('used_operator_monitoring', null, 'nao'); // POSTHOG_NEW
      } else if (operator.name == 'ADJ') {
        PostHogSendMonitoring('used_operator_monitoring', null, 'adj'); // POSTHOG_NEW
      } else if (operator.name == '‘’   ‘’') {
        PostHogSendMonitoring('used_operator_monitoring', null, 'aspas'); // POSTHOG_NEW
      }
    }
    addOperator(operator.addOperator);
  };

  return (
    <div className="operators">
      <span>
        <strong>Operadores:</strong> são formas mais inteligentes de encontrar
        os melhores resultados
      </span>

      <div className="operators-buttons">
        {operators.map((operator, index) => (
          <div
            key={index}
            className="operator-wrapper"
            onMouseEnter={() => handleMouseEnter(operator)}
            onMouseLeave={handleMouseLeave}
          >
            <button
              className="operator-button"
              onClick={() => handleButtonClick(operator)}
            >
              {operator.name}
            </button>

            {hoveredOperator?.name === operator.name && (
              <div
                className={`operator-tooltip-monitoramento ${top && !hasSearchBarResults ? 'in-results' : ''}`}
              >
                <OperatorCard
                  operator={hoveredOperator}
                  addOperator={(data) => console.log('Add Operator:', data)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

OperatorsHelperOpen.propTypes = {
  addOperator: PropTypes.func,
  hasSearchResults: PropTypes.bool,
  hasSearchBarResults: PropTypes.bool,
};

export default OperatorsHelperOpen;
