import React from 'react';
import { UserTick } from 'iconsax-react';
import { Dropdown } from 'rsuite';
import { withRouter } from 'react-router-dom';

import { PostHogSendHeader } from '../../../utils/PostHogSendEvents';

const MyProfile = ({ eventKey, ...props }) => {
  return (
    <Dropdown.Item eventKey={eventKey}>
      <button
        className="option-menu-default"
        data-testid="menu-dropdown-perfil"
        onClick={() => {
          props.history.push('/perfil');
          PostHogSendHeader('perfil_options', 'meu_perfil'); // POSTHOG_NEW
        }}
      >
        <UserTick size="20" color="#0069F5" />
        <label> Meu Perfil </label>
      </button>
    </Dropdown.Item>
  );
};

export default withRouter(MyProfile);
