import { Document } from 'iconsax-react';
import { Button } from '../../../../../ds-components';
import { toast } from 'react-toastify';
import { cn } from '../../../../../lib/utils';
import { Tooltip, Whisper } from 'rsuite';
import {
  PostHogSendResultsCard,
  PostHogSendBulkAction,
} from '../../../../../utils/PostHogSendEvents';

const CiteButton = ({
  children,
  cards,
  appearance,
  icon,
  className,
  pageView,
  currentPageBulkAction,
}) => {
  const copyCitationToClipboard = (cards, event) => {
    // Impede o evento de chegar na `div`
    // É importante pq senão quandi clicamos em ler mais ele vai ficar adicionando a decisão no bulkaction
    event.stopPropagation();

    let citeText = '';
    cards.forEach((card) => {
      const div = document.createElement('div');
      div.innerHTML = card.content[0].content;
      const ementa = div.innerText;

      const cardInformation =
        `\n\n${card.name ? card.name + ', ' : ''}` +
        `${card.entity && card.entity.name ? 'Órgão: ' + card.entity.name + '. ' : ''}` +
        `${card.relator && card.relator.name ? 'Relator: ' + card.relator.name + '. ' : ''}` +
        `${card.dataJul ? ' ' + card.dataJul : ''}` +
        `${card.dataJul && card.dataPub ? ', ' : card.dataJul ? '. ' : ''}` +
        `${card.dataPub ? ' ' + card.dataPub + '.' : ''}`;

      if (citeText.length > 0)
        citeText += '\n\n---------------------------------------------\n\n';
      citeText += ementa + cardInformation;
    });

    navigator.clipboard.writeText(citeText);
    PostHogSendResultsCard('copied_citacoes', pageView); // POSTHOG_NEW
    if (currentPageBulkAction == 'resultpage') {
      PostHogSendBulkAction('copy_quote_bulkaction'); // POSTHOG_NEW
    }
    toast.success(
      cards.length === 1 ? 'Citação copiada' : 'Citações copiadas',
      {
        icon: <Document size={24} color="#12B76A" />,
        className: 'bottom-[50px]',
      },
    );

    // posthog.capture('copy_to_clipboard', {
    //   action: 'copy_to_clipboard',
    //   id: card.id,
    // });
    // postHubSpotEvent('app_copytoclipboard', { app_copytoclipboard: card.id });
  };

  return (
    <Button
      appearance={appearance}
      size="sm"
      icon={icon}
      onClick={(event) => copyCitationToClipboard(cards, event)}
      className={cn('citar-tour', className)}
      tooltipText="Copiar a citação"
    >
      {children}
    </Button>
  );
};

export default CiteButton;
