import React from 'react';
import { withRouter } from 'react-router-dom';
import { Panel, ButtonToolbar, toaster, Message, Button } from 'rsuite';
import { requester } from '../../../requesters';

import './UnsubscribeMonitoring.scss';

class UnsubscribeMonitoring extends React.Component {
  state = {
    requesting: false,
  };

  confirmUnsubscribe = () => {
    const params = new URLSearchParams(this.props.location.search);
    const user = params.get('u');
    const date = params.get('d');
    const hash = params.get('h');
    if (user && date && hash && hash !== 'None') {
      this.setState({ requesting: true });
      requester.monitoring
        .unsubscribe(user, date, hash)
        .then((response) => {
          toaster.push(
            <Message
              type="success"
              style={{ marginBottom: '50px' }}
              showIcon
              closable
              duration={8000}
            >
              Monitoramento parado com sucesso!
            </Message>,
          );
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message
              style={{ marginBottom: '50px' }}
              type="error"
              showIcon
              closable
              duration={8000}
            >
              Link de descadastramento inválido. Clique novamente no link do
              e-mail ou entre em contato com o suporte@turivius.com.
            </Message>,
          );
        })
        .finally(() => {
          this.setState({ requesting: false });
        });
    } else {
      toaster.push(
        <Message type="error" showIcon closable duration={8000}>
          Link de descadastramento inválido. Clique novamente no link do e-mail
          ou entre em contato com o suporte@turivius.com.
        </Message>,
      );
    }
  };

  render() {
    const { requesting } = this.state;
    return (
      <div className="unsubscribe-monitoring-container">
        <Panel
          className="unsubscribe-monitoring-panel"
          shaded
          bordered
          header={'Cancelar inscrição no monitoramento jurisprudencial'}
        >
          Você gostaria de cancelar sua inscrição no monitoramento
          jurisprudencial da Turivius?
          <br />
          <i>
            Esta ação fará com que seus termos parem de ser monitorados e os
            e-mails parem de ser enviados.
          </i>
          <br />
          <br />
          <ButtonToolbar>
            <Button
              data-testid="btn-sign-in"
              appearance="primary"
              onClick={() => this.props.history.push('/')}
              disabled={requesting}
            >
              Entrar na Turivius
            </Button>
            <Button
              data-testid="btn-stop-monitoring-emails"
              color="red"
              appearance="primary"
              onClick={this.confirmUnsubscribe}
              loading={requesting}
            >
              Parar monitoramento
            </Button>
          </ButtonToolbar>
        </Panel>
      </div>
    );
  }
}

export default withRouter(UnsubscribeMonitoring);
