import { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import DefaultOperatorFilter from '../../../jurisprudential-search/components/results/FilterList/Filters/DefaultOperatorFilter';
import SingleFilter from '../../../jurisprudential-search/components/results/FilterList/Filters/SingleFilter';
import SelectSharePeopleMonitoring from '../../components/MonitoringConfigs/SelectSharePeopleMonitoring';
import TermLabelMonitoring from '../../components/MonitoringConfigs/TermLabelMonitoring';
import SelectFrequencyMonitoring from '../../components/MonitoringConfigs/SelectFrequencyMonitoring';
import ToggleEmailMonitoring from '../../components/MonitoringConfigs/ToggleEmailMonitoring';
import ToggleSendNotificationMonitoring from '../../components/MonitoringConfigs/ToggleSendNotificationMonitoring';
import SelectEntitiesMonitoring from '../../components/MonitoringConfigs/SelectEntitiesMonitoring';
import { Button } from '../../../../ds-components';
import { AddCircle, ArrowLeft2, ArrowRight2, Setting2 } from 'iconsax-react';
import { Col, Grid, Message, Modal, Steps, useToaster } from 'rsuite';
import InteiroTeorFilter from '../MonitoringConfigs/MonitoringFilters/InteiroTeorFilter';
import SynonymsFilter from '../MonitoringConfigs/MonitoringFilters/SynonymsFilter';
import { updateCheckllist } from '../../../../services/checklist';
import CustomerXTracking from '../../../../utils/CustomerXTracking';

import { requester } from '../../../../requesters';
import { PostHogSendMonitoring } from '../../../../utils/PostHogSendEvents';

const CreateNewMonitoringButton = ({
  edit,
  menu,
  monitoring,
  entities,
  shared,
  onCreateMonitoring,
  onEditMonitoring,
  getMonitoring,
  ...props
}) => {
  const toaster = useToaster();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [stepStatus, setStepStatus] = useState('process');

  const [term, setTerm] = useState('');
  const [termError, setTermError] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [sendNotification, setSendNotification] = useState(true);
  const [allEntities, setAllEntities] = useState(false);
  const [frequency, setFrequency] = useState('D');
  const [monitoringWeekDay, setMonitoringWeekDay] = useState(null);
  const [monitoringMonthDay, setMonitoringMonthDay] = useState(null);
  const [selectedEntities, setSelectedEntities] = useState([]);

  const [defaultOperator, setDefaultOperator] = useState('e');
  const [synonymsFilter, setSynonymsFilter] = useState(true);
  const [inteiroTeorFilter, setInteiroTeorFilter] = useState([
    'ementa',
    'full_text',
  ]);
  const [singleFilter, setSingleFilter] = useState(1);

  const [sharedWith, setSharedWith] = useState([]);
  const [allValue, setAllValue] = useState();

  const handleModal = (open) => {
    setModal(!modal);

    if (open && edit) {
      getDataMonitoring();
    }
  };

  async function updTour() {
    const check = JSON.parse(localStorage.getItem('checklist'));
    if (check?.trail?.monitoring) {
      return;
    } else {
      // await updateCheckllist(check?.id, 'monitoring');
    }
  }

  const createMonitoring = async () => {
    setLoading(true);

    const entities = await requester.cards.getEntities();
    const entitiesIDAndClass = entities.map(
      ({ id, initials, class: entityClass }) => ({
        id,
        initials,
        class: entityClass,
      }),
    );

    const entityMap = entitiesIDAndClass.reduce((acc, entity) => {
      acc[entity.id] = entity;
      return acc;
    }, {});

    const usedEntitiesByClass = selectedEntities.reduce((acc, id) => {
      if (entityMap[id]) {
        const { initials, class: entityClass } = entityMap[id];
        if (!acc[entityClass]) {
          acc[entityClass] = new Set();
        }
        acc[entityClass].add(initials);
      }
      return acc;
    }, {});

    const allClasses = [...new Set(entitiesIDAndClass.map((e) => e.class))];
    allClasses.forEach((cls) => {
      if (!usedEntitiesByClass[cls]) {
        usedEntitiesByClass[cls] = new Set();
      }
    });

    Object.keys(usedEntitiesByClass).forEach((cls) => {
      usedEntitiesByClass[cls] = [...usedEntitiesByClass[cls]];
    });

    let userId = localStorage.getItem('user'),
      companyId = localStorage.getItem('company'),
      all_entities = allEntities
        ? allEntities
        : selectedEntities.length === entities.length
          ? true
          : allEntities,
      filters = {
        entity: all_entities ? [] : selectedEntities,
        sort: singleFilter,
        default_operator: defaultOperator,
        fields: inteiroTeorFilter,
        synonyms: synonymsFilter,
      };

    onCreateMonitoring(
      term,
      frequency,
      sendEmail,
      sendNotification,
      all_entities,
      filters,
      sharedWith,
      monitoringWeekDay,
      monitoringMonthDay,
      userId,
      companyId,
    )
      .then(() => {
        if (sharedWith.length != 0) {
          if (sharedWith.length == allValue) {
            PostHogSendMonitoring(
              'shared_monitoring',
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
            ); // POSTHOG_NEW
          }
          if (sharedWith.length > 0 && sharedWith.length < allValue) {
            PostHogSendMonitoring('shared_monitoring'); // POSTHOG_NEW
          }
        }
        PostHogSendMonitoring('send_email_monitoring', null, null, sendEmail); // POSTHOG_NEW
        PostHogSendMonitoring(
          'send_notification_monitoring',
          null,
          null,
          sendNotification,
        ); // POSTHOG_NEW

        updTour();
        getMonitoring();
        handleModal(false);
        setSettingsDefault();
        PostHogSendMonitoring('create_save_monitoring', 'monitoringpage'); // POSTHOG_NEW
        if (allEntities == true) {
          PostHogSendMonitoring('all_bodies_monitoring'); // POSTHOG_NEW
        }
        PostHogSendMonitoring(
          'frequency_monitoring',
          null,
          frequency == 'D' ? 'daily' : frequency == 'W' ? 'weekly' : 'monthly',
        ); // POSTHOG_NEW

        PostHogSendMonitoring(
          'chose_administrative_body_monitoring',
          null,
          null,
          null,
          usedEntitiesByClass['Órgãos Administrativos'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_federal_court_monitoring',
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Justiça Federal'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_state_court_monitoring',
          null,
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Justiça Estadual'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_labor_court_monitoring',
          null,
          null,
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Justiça do Trabalho'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_high_court_monitoring',
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Tribunais Superiores'],
        ); // POSTHOG_NEW
      })
      .catch((err) => {
        let message = {
          header: '',
          type: '',
          message: '',
        };
        console.log(err);

        if (err.status === 400) {
          message.header = 'Atenção';
          message.type = 'warning';
          message.header = `O termo ${term} já está sendo monitorado.`;
        } else {
          message.header = 'Erro ao criar monitoramento';
          message.type = 'error';
          message.header = 'Houve um problema de conexão, tente novamente';
        }
      })
      .finally(() => {
        setLoading(false);

        try {
          CustomerXTracking('action', 'create_monitoring');
        } catch {}
      });
  };

  const editMonitoring = async () => {
    setLoading(true);
    const entities = await requester.cards.getEntities();
    const entitiesIDAndClass = entities.map(
      ({ id, initials, class: entityClass }) => ({
        id,
        initials,
        class: entityClass,
      }),
    );

    const entityMap = entitiesIDAndClass.reduce((acc, entity) => {
      acc[entity.id] = entity;
      return acc;
    }, {});

    const usedEntitiesByClass = selectedEntities.reduce((acc, id) => {
      if (entityMap[id]) {
        const { initials, class: entityClass } = entityMap[id];
        if (!acc[entityClass]) {
          acc[entityClass] = new Set();
        }
        acc[entityClass].add(initials);
      }
      return acc;
    }, {});

    const allClasses = [...new Set(entitiesIDAndClass.map((e) => e.class))];
    allClasses.forEach((cls) => {
      if (!usedEntitiesByClass[cls]) {
        usedEntitiesByClass[cls] = new Set();
      }
    });

    Object.keys(usedEntitiesByClass).forEach((cls) => {
      usedEntitiesByClass[cls] = [...usedEntitiesByClass[cls]];
    });

    let filters = {
      entity: selectedEntities,
      sort: singleFilter,
      default_operator: defaultOperator,
      fields: inteiroTeorFilter,
      synonyms: synonymsFilter,
    };

    onEditMonitoring(
      monitoring.id,
      sharedWith,
      'Monitoring',
      term,
      frequency,
      sendEmail,
      sendNotification,
      allEntities,
      filters,
      monitoringWeekDay,
      monitoringMonthDay,
    )
      .then((response) => {
        if (sharedWith.length != 0) {
          if (sharedWith.length == allValue) {
            PostHogSendMonitoring(
              'shared_monitoring',
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
            ); // POSTHOG_NEW
          }
          if (sharedWith.length > 0 && sharedWith.length < allValue) {
            PostHogSendMonitoring('shared_monitoring'); // POSTHOG_NEW
          }
        }
        PostHogSendMonitoring('send_email_monitoring', null, null, sendEmail); // POSTHOG_NEW
        PostHogSendMonitoring(
          'send_notification_monitoring',
          null,
          null,
          sendNotification,
        ); // POSTHOG_NEW
        if (allEntities == true) {
          PostHogSendMonitoring('all_bodies_monitoring'); // POSTHOG_NEW
        }
        PostHogSendMonitoring('edit_config_monitoring'); // POSTHOG_NEW
        PostHogSendMonitoring(
          'frequency_monitoring',
          null,
          frequency == 'D' ? 'daily' : frequency == 'W' ? 'weekly' : 'monthly',
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_administrative_body_monitoring',
          null,
          null,
          null,
          usedEntitiesByClass['Órgãos Administrativos'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_federal_court_monitoring',
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Justiça Federal'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_state_court_monitoring',
          null,
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Justiça Estadual'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_labor_court_monitoring',
          null,
          null,
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Justiça do Trabalho'],
        ); // POSTHOG_NEW
        PostHogSendMonitoring(
          'chose_high_court_monitoring',
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          usedEntitiesByClass['Tribunais Superiores'],
        ); // POSTHOG_NEW
        setSettingsDefault();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleModal(false);
        getMonitoring();
      });
  };

  function setSettingsDefault() {
    setStep(0);
    setTerm(''),
      setFrequency('D'),
      setSendEmail(true),
      setSendNotification(true),
      setAllEntities(false),
      setSharedWith([]),
      setMonitoringWeekDay(null),
      setMonitoringMonthDay(null);
  }

  function groupArray(arr, groupBy) {
    let groupedArray = [];
    let categories = {};

    arr?.forEach((elem) => {
      categories[elem[groupBy]] = {};
      categories[elem[groupBy]].label = elem[groupBy];
      categories[elem[groupBy]].value = elem[groupBy];
    });

    Object.keys(categories).forEach((category, i) => {
      groupedArray.push([]);
      arr.forEach((elem) =>
        elem[groupBy] === category
          ? groupedArray[i].push({ value: elem.id, label: elem.initials })
          : null,
      );
      categories[category].children = groupedArray[i];
    });
    return Object.values(categories);
  }

  function changeValue(value) {
    let entitiesFilter = [];

    value?.forEach((e) => {
      if (typeof e === 'string') {
        entities.forEach((entity) => {
          if (entity.class === e) {
            entitiesFilter.push(entity.id);
          }
        });
      } else {
        entitiesFilter.push(e);
      }
    });

    setSelectedEntities([...entitiesFilter]);
  }

  function getDataMonitoring() {
    let anyDefaults = {
      default_operator: 'e',
      synonyms: true,
      fields: ['ementa', 'full_text'],
      sort: 1,
    };

    setTerm(monitoring.query);
    setFrequency(monitoring.frequency);
    setSendEmail(monitoring.send_email);
    setSendNotification(monitoring.send_notification);
    setAllEntities(monitoring.all_entities);
    setSharedWith(monitoring.shared_with);
    setSelectedEntities(monitoring.filters?.entity);
    setMonitoringWeekDay(monitoring.monitoring_week_day);
    setMonitoringMonthDay(monitoring.monitoring_month_day);
    setDefaultOperator(
      monitoring.filters.default_operator || anyDefaults.default_operator,
    );
    setSynonymsFilter(
      monitoring.filters.synonyms === undefined
        ? anyDefaults.synonyms
        : monitoring.filters.synonyms,
    );
    setInteiroTeorFilter(monitoring.filters.fields || anyDefaults.fields);
    setSingleFilter(monitoring.filters.sort || anyDefaults.sort);
  }

  const onNext = () => {
    if (term.length < 3) {
      setStepStatus('error');
      setTermError(true);
      toaster.push(
        <Message header="Atenção" type="warning" showIcon closable>
          Seu monitoramento deve ter pelo menos três caracteres
        </Message>,
        { duration: 5000 },
      );
    } else if (!allEntities && selectedEntities.length === 0) {
      setStepStatus('error');
      toaster.push(
        <Message header="Atenção" type="warning" showIcon closable>
          Você deve selecionar ao menos um orgão
        </Message>,
        { duration: 5000 },
      );
    } else {
      setStepStatus(stepStatus === 'error' ? null : '');
      setTermError(false);
      setStep(step + 1);
    }
  };

  const onPrevious = () => {
    setStep(step - 1);
  };

  return (
    <div>
      {!shared ? (
        menu ? (
          <Button
            appearance="secondary"
            size="sm"
            disabled={props.disabled}
            icon={<Setting2 color="#667085" size={16} />}
            onClick={props.disabled ? () => {} : () => handleModal(true)}
          >
            Configuração
          </Button>
        ) : (
          <Button
            className="create-new-monitoring-btn"
            appearance="primary"
            size="md"
            disabled={props.disabled}
            icon={<AddCircle color="#ffffff" size={20} />}
            onClick={props.disabled ? () => {} : () => handleModal(false)}
          >
            Criar monitoramento
          </Button>
        )
      ) : null}

      <Modal size={'sm'} open={modal} onClose={() => handleModal(false)}>
        <Modal.Title
          style={{ marginBottom: 15, fontWeight: 700, fontSize: 20 }}
        >
          {edit
            ? `Editar monitoramento: ${monitoring.query}`
            : 'Novo monitoramento'}
        </Modal.Title>

        <Modal.Header>
          <Steps current={step}>
            <Steps.Item title="Configurações" />
            <Steps.Item title="Filtros" />
            <Steps.Item title="Compartilhar" />
          </Steps>
        </Modal.Header>

        <Modal.Body>
          {step === 0 ? (
            <div className="form-monitoring">
              <TermLabelMonitoring
                term={term}
                setTerm={setTerm}
                termError={termError}
              />

              <SelectFrequencyMonitoring
                frequency={frequency}
                monitoringWeekDay={monitoringWeekDay}
                monitoringMonthDay={monitoringMonthDay}
                setFrequency={setFrequency}
                setMonitoringWeekDay={setMonitoringWeekDay}
                setMonitoringMonthDay={setMonitoringMonthDay}
              />

              <ToggleEmailMonitoring
                sendEmail={sendEmail}
                setSendEmail={setSendEmail}
              />

              <ToggleSendNotificationMonitoring
                sendNotification={sendNotification}
                setSendNotification={setSendNotification}
              />

              <SelectEntitiesMonitoring
                setSelectedEntities={setSelectedEntities}
                setAllEntities={setAllEntities}
                allEntities={allEntities}
                groupArray={groupArray}
                selectedEntities={selectedEntities}
                changeValue={changeValue}
                entities={entities}
              />
            </div>
          ) : step === 1 ? (
            <Grid fluid>
              <Col sm={24} className="search-operators">
                <DefaultOperatorFilter
                  context={'Monitoring'}
                  defaultOperator={defaultOperator}
                  setDefaultOperator={setDefaultOperator}
                />

                <SynonymsFilter
                  context={'Monitoring'}
                  synonymsFilter={synonymsFilter}
                  setSynonymsFilter={setSynonymsFilter}
                />

                <InteiroTeorFilter
                  context={'Monitoring'}
                  inteiroTeorFilter={inteiroTeorFilter}
                  setInteiroTeorFilter={setInteiroTeorFilter}
                />

                <SingleFilter
                  context={'Monitoring'}
                  singleFilter={singleFilter}
                  setSingleFilter={setSingleFilter}
                  label="Ordenação de Resultados"
                  labelKey="name"
                  placeholder="Ordenação de Resultados"
                />
              </Col>
            </Grid>
          ) : (
            <SelectSharePeopleMonitoring
              sharePeople={sharedWith}
              setSharePeople={setSharedWith}
              setAllValue={setAllValue}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="monitoring-create-new_modal-footer">
            <Button
              appearance="secondary"
              size="sm"
              disabled={step === 0}
              onClick={onPrevious}
              icon={<ArrowLeft2 />}
            >
              Anterior
            </Button>

            {step > 1 ? (
              edit ? null : (
                <Button
                  appearance="primary"
                  loading={loading}
                  onClick={createMonitoring}
                >
                  Criar Monitoramento
                </Button>
              )
            ) : (
              <Button
                appearance="secondary"
                size="sm"
                disabled={step === 2}
                onClick={onNext}
                icon={<ArrowRight2 />}
                iconPosition="right"
              >
                Próximo
              </Button>
            )}
            {edit ? (
              <Button
                appearance="primary"
                loading={loading}
                onClick={editMonitoring}
              >
                Salvar monitoramento
              </Button>
            ) : null}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withRouter(CreateNewMonitoringButton);
