import {
  faCompass,
  faArrowTrendDown,
  faBuildingColumns,
} from '@fortawesome/free-solid-svg-icons';
export const topics = [
  'Férias',
  'Horas Extras',
  'FGTS',
  'Aviso Prévio',
  'Desconto Sindical',
  'Base de Cálculo',
  'Multa do Artigo 477 da CLT',
  'Adicional de Insalubridade',
  'Danos Morais',
  'Periculosidade',
  'Acidente de Trabalho',
];

export const listing = [
  {
    id: 'cnpj',
    title: 'Buscar por CNPJ',
    icon: 'Buildings',
    description:
      'Tome decisões estratégicas com base no contencioso da empresa nos Tribunais.<br/> <br/> Aplique filtros por assunto e/ou data e personalize gráficos de jurimetria.',
    backgroundColor: '#84D4FF',
    fontColor: '#02032F',
    questions: [
      {
        questionId: 1,
        required: true,
        question: 'Qual o CNPJ a ser pesquisado?',
        description:
          'Após solicitar o relatório, os dados são capturados e, em até 48 horas, você receberá um e-mail informando que o dashboard está disponível.',
        field: 'cnpj',
        type: 'cnpj-input',
      },
    ],
  },
  {
    id: 'assunto',
    title: 'Buscar por Assunto',
    icon: 'Message',
    description:
      'Gere gráficos da tendência do número de relcamatórias envolvendo determinado pedido. <br/><br/> Potencialize a sua estratégia com base em dados e filtros personalizáveis.',
    backgroundColor: '#02032F',
    fontColor: '#84D4FF',
    questions: [
      {
        questionId: 1,
        required: true,
        question: 'Qual o assunto a ser pesquisado?',
        description:
          'Selecione de 1 a 5 assuntos para análise de jurimetria nos Tribunais do Trabalho.',
        type: 'tag-input',
        field: 'assunto',
      },
    ],
  },
  {
    id: 'estado',
    title: 'Buscar por Estado',
    icon: 'Map1',
    description:
      'Visualize a tendência de julgamentos de determinado Tributal Regional do Trabalho. <br/><br/> Realize a análise comparativa de dois ou mais Tribunais conforme os filtros escolhidos.',
    backgroundColor: '#84D4FF',
    fontColor: '#02032F',
    questions: [
      {
        questionId: 1,
        required: true,
        question: 'Quais os tribunais de interesse?',
        description:
          'Selecione um ou mais tribunais e crie dashboards de jurimetria por tribunal.',
        field: 'estado',
        type: 'toggle-button',
      },
    ],
  },
];
