import { useCallback, useEffect, useState } from 'react';
import { Button, FlexboxGrid, Loader } from 'rsuite';
import { requester } from '../../../requesters';
import { TuriviusCrypt } from '../../../utils/ValidateInputs';

import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './JurimetriaTrabalhista.scss';
import FlagSystem from '../../../components/FlagSystem';
import UpgradeModal from '../../Billing/components/UpgradeModel';

const DashJurimetriaTrabalhista = (props) => {
  const [researchObject, setResearchObject] = useState('');
  const [useCaseName, setUseCaseName] = useState('');
  const [loading, setLoading] = useState(true);
  const [billingModalOpen, setBillingModalOpen] = useState(false);
  const [conditionStatus, setConditionStatus] = useState('upgrade'); // EXPLANATION: This can set limit or upgrade

  const getTokenAccess = async () => {
    await requester.jurimetrics
      .getTokenTrabalhista()
      .then((token) => {
        redirectMVBP(token.b64);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const checkBillingCredit = async () => {
    const params = new URLSearchParams(props.location.search);
    const caseName = params.get('case');
    const paramsSearch = JSON.parse(params.get('filters'));

    requester.segmentation
      .validateAction('search_labor_jurimetry')
      .then((response) => {
        if (response?.status == 403) {
          setBillingModalOpen(true);
          if (response.message?.has_permission == 0) {
            setConditionStatus('upgrade');
          } else {
            setConditionStatus('limit');
          }

          return;
        }

        const event_data = {
          action: 'search_labor_jurimetry',
          event_data: {
            case: caseName,
            cnpj: paramsSearch?.cnpj,
          },
        };
        requester.segmentation.sendEvent(event_data);
      })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        console.log(response);
      });
  };

  const redirectMVBP = (token) => {
    let obj = {};
    const params = new URLSearchParams(props.location.search);
    const URL = process.env.REACT_APP_DASH_TRABALHISTA;
    const caseName = params.get('case');
    const paramsSearch = JSON.parse(params.get('filters'));

    obj['authorization'] = token;
    obj['answers'] = paramsSearch;

    setResearchObject(
      `${URL}${caseName}?code=${TuriviusCrypt.EncodeText(
        'base64',
        JSON.stringify(obj),
      )}`,
    );
    setUseCaseName(caseName);
    setLoading(false);
  };

  useEffect(() => {
    document.title = 'Busca Jurimétrica Trabalhista';
    getTokenAccess();
    checkBillingCredit();
  }, ['']);

  const ToggleBillingModal = useCallback(() => {
    setBillingModalOpen((prev) => !prev);
    props.history.push('/jurimetria-trabalhista/');
  });

  return (
    <div
    //   style={{ backgroundColor: "#f6f6f6", height: "100vh", overflowY: "auto" }}
    >
      <UpgradeModal
        open={billingModalOpen}
        feature={'Jurimetria Trabalhista'}
        conditionStatus={conditionStatus}
        onClose={ToggleBillingModal}
      ></UpgradeModal>
      <FlexboxGrid className="jurimetrics-trabalhista-iframe">
        <FlexboxGrid.Item colspan={24}>
          <FlagSystem current={`jurimetrics-${useCaseName}`} />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={24} style={{ height: '100%' }}>
          <div className="conteiner-button-back">
            <Button className="btn-back" onClick={() => props.history.goBack()}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ fontSize: 20, color: '#84d4ff' }}
              />
              Voltar
            </Button>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="conteiner-iframe">
              <iframe
                frameBorder="0"
                id="printf"
                name="printf"
                src={researchObject}
              />
            </div>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default withRouter(DashJurimetriaTrabalhista);
