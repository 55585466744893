import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FlexboxGrid, Grid, Message, Pagination, Row, toaster } from 'rsuite';
import TuriviusPreloader from '../../../app/TuriviusPreloader';
import FlagSystem from '../../../components/FlagSystem';
import { CollectionSkeleton } from '../../../components/Skeleton';
import { posthog, requester } from '../../../requesters';
import { useCollectionTags } from '../../../store/ducks/collectionTags';
import { Types as BulkactionTypes } from '../../../store/ducks/collectionsBulkaction';
import BulkActionBar from '../../jurisprudential-search/components/results/BulkActionBar/BulkActionBar';
import CollectionHeader from '../components/Collection/CollectionHeader/CollectionHeader';
import EmptyCollection from '../components/Collection/EmptyCollection/EmptyCollection';
import CollectionsResultCard from '../components/Collections/CollectionsResultCard';
import './Collection.scss';
import { getAllCardsByCollectionId } from '../../../requesters/requesters/cards';
import { getCollection } from '../../../requesters/requesters/collections';

const Collection = () => {
  const LIMIT = 10;
  const {
    collections: collectionsTags,
    clear: clearCollectionTags,
    fetch: fetchCollectionTags,
  } = useCollectionTags();

  const { collectionId } = useParams();
  const collectionTags = collectionsTags.find(
    (collection) => collection.id === collectionId,
  )?.tags;
  const [
    theMatchResultsThatHaveCollections,
    setTheMatchResultsThatHaveCollections,
  ] = useState([]);
  const reference = useRef(null);
  const triggerSuccessChecklist = useRef(null);
  const [collection, setCollection] = useState(null);
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [dataDecisions, setDataDecisions] = useState();

  const [paginatedDataDecisions, setPaginatedDataDecisions] = useState([]);
  const startIndex = (activePage - 1) * LIMIT;
  const endIndex = startIndex + LIMIT;

  useEffect(() => {
    setPaginatedDataDecisions(
      Array.isArray(dataDecisions) && dataDecisions[0]
        ? dataDecisions[0].slice(startIndex, endIndex)
        : [],
    );
  });

  const [requestingComments, setRequestingComments] = useState(true);
  const [comments, setComments] = useState(null);

  const openSuccessChecklist = () => triggerSuccessChecklist.current?.open();

  const dispatch = useDispatch();

  const selectedCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );
  const fetchData = async () => {
    const getCards = await getAllCardsByCollectionId(userId, collectionId);
    setDataDecisions(getCards.cards);
    const response = await getCollection(userId, collectionId);
    setCollection(response);
  };

  useEffect(() => {
    fetchData();
  }, [collectionId]);

  const userId = localStorage.getItem('user');

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCollection(userId, collectionId);
      setCollection(response);
    };
    fetchData();
  }, [userId]);

  const getEntities = async () => {
    let entity = [];

    await requester.cards
      .getEntities()
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });

    await requester.cards
      .getEntities('trabalhista')
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });

    setEntities(entity);
  };

  const getCollectionComments = () => {
    setRequestingComments(true);
    requester.collections
      .getCollectionComments(collectionId)
      .then((response) => {
        setComments(response);
      })
      .catch(console.error)
      .finally(() => setRequestingComments(false));
  };

  const addComment = (cardId, comment) => {
    setComments({
      ...comments,
      [cardId]: comments[cardId] ? [...comments[cardId], comment] : [comment],
    });
  };

  const removeComment = (cardId, commentId) => {
    setComments({
      ...comments,
      [cardId]: comments[cardId].filter((comment) => comment.id !== commentId),
    });
  };

  const onChangePageHandler = (page) => {
    setActivePage(page);
    const body = document.querySelector('.rs-content');
    body.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchMatchResults = async () => {
      if (dataDecisions == null) return;

      try {
        const cardIds = dataDecisions[0].map((card) => card.id);
        const response =
          await requester.collections.matchResultsThatHaveCollections(
            encodeURIComponent(JSON.stringify(cardIds)),
          );

        const uniqueResults = [...new Set(response)];
        setTheMatchResultsThatHaveCollections(uniqueResults);
      } catch (error) {
        console.error('Erro ao buscar resultados de coleções:', error);
        setLoading(false);
      }
    };

    fetchMatchResults();
  }, [dataDecisions]);

  useEffect(() => {
    posthog.capture('$pageview');
    document.title = `Coleção #${collectionId}`;

    getEntities();
    getCollectionComments();
    fetchCollectionTags(collectionId);

    return () => {
      clearCollectionTags();
      dispatch({ type: BulkactionTypes.UNSELECT_ALL });
    };
  }, [collectionId]);

  return (
    <div>
      <FlexboxGrid
        justify="center"
        className={`transition duration-300 ease-out ${selectedCards.length > 0 ? 'translate-y-0' : '-translate-y-20'}`}
      >
        <FlexboxGrid.Item colspan={24} className="sticky top-0 z-20">
          <BulkActionBar
            theMatchResultsThatHaveCollections={
              theMatchResultsThatHaveCollections
            }
            fetchData={fetchData}
            collection={collection}
            collectionComments={comments}
            collectionTags={collectionTags}
            showExportCardsAsCSV={true}
            showSaveCardToCollectionButton={true}
            showRemoveCardFromCollectionButton={true}
            showAddTagDecision={true}
            showRemoveTagDecision={true}
            activePage={activePage}
            currentPageBulkAction={'collectionpage'}
            pageView="collectionpage"
            selectedCardsNumber={selectedCards.length}
          />
        </FlexboxGrid.Item>

        {collection ? (
          <FlexboxGrid.Item colspan={24} className="pt-2">
            <FlagSystem
              current="collection"
              titleBreadCrumb={collection.name}
              additionalRoute={`${collection.id} - ${collection.name} `}
              openSuccessChecklist={openSuccessChecklist}
            />
          </FlexboxGrid.Item>
        ) : (
          <div>Carregando coleção...</div>
        )}

        <FlexboxGrid.Item colspan={24} id="collection-cont">
          {loading || !dataDecisions ? (
            <div className="collection-section">
              <div className="collection-container">
                <TuriviusPreloader backdrop />
                <div style={{ padding: 20 }}>
                  <Grid fluid>
                    <Row>
                      <CollectionSkeleton />
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          ) : paginatedDataDecisions?.length > 0 ? (
            <Grid fluid style={{ padding: 20 }}>
              <Row style={{ paddingBottom: 10 }}>
                <CollectionHeader collection={collection} />
              </Row>
              <Row>
                <div
                  id="collection-infinite-scroll"
                  ref={reference}
                  style={{
                    minHeight: 400,
                    overflow: 'auto',
                  }}
                >
                  {paginatedDataDecisions &&
                    paginatedDataDecisions.map((card) => (
                      <CollectionsResultCard
                        key={card.id}
                        card={card}
                        collectionId={collectionId}
                        collection={collection}
                        entities={entities}
                        entity={
                          entities.filter((item) => item.id === card.entity)[0]
                        }
                        requestingComments={requestingComments}
                        comments={
                          comments
                            ? comments[card.id]
                              ? comments[card.id]
                              : []
                            : comments
                        }
                        addComment={addComment}
                        removeComment={removeComment}
                        context={'Single Collection'}
                        theMatchResultsThatHaveCollections={
                          theMatchResultsThatHaveCollections
                        }
                        collectionTags={collectionTags}
                        getCollectionComments={getCollectionComments}
                      />
                    ))}

                  <Pagination
                    prev
                    next
                    first
                    last
                    total={dataDecisions[0]?.length}
                    activePage={activePage}
                    onChangePage={(page) => onChangePageHandler(page)}
                    limit={LIMIT}
                    maxButtons={10}
                  />
                </div>
              </Row>
            </Grid>
          ) : (
            <EmptyCollection />
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default Collection;
