import { useMemo } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { Button } from '../../../../../ds-components';
import { posthog } from '../../../../../requesters';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import CiteButton from './CiteButton';
import { ExportSquare } from 'iconsax-react';
import { ResumeInteiroTeor } from './ResumeInteiroTeor';
import SaveCardToCollectionButton from './SaveCardToCollectionButton';
import { ArchiveAdd, ArchiveTick, Copy } from 'iconsax-react';
import { useCollections } from '../../../../../store/ducks/theCollections';

import { PostHogSendResultsCard } from '../../../../../utils/PostHogSendEvents';

export const ResultButtonGroup = ({
  card,
  query,
  theMatchResultsThatHaveCollections,
  staticContext,
  pageView,
  ...props
}) => {
  const { collections: theCollections } = useCollections();
  const { pathname } = useLocation();

  const isInMonitoring = useMemo(
    () => pathname.includes('/monitoramento'),
    [pathname],
  );

  const openIntegra = () => {
    window.open(card.url, '_blank');
    posthog.capture('open_integra', {
      action: 'open_integra',
      id: card.id,
      entity: card.entity.id,
    });
    postHubSpotEvent('app_openintegra', { app_openintegra: card.id });

    PostHogSendResultsCard('open_integra', pageView); // POSTHOG_NEW
  };

  const theAllCollectionsInOne = useMemo(() => {
    if (!theCollections) return;
    const collections = Object.entries(theCollections)
      ?.map(([_, object]) => object.boards)
      .flat();
    return collections;
  }, [theCollections]);

  const isItInACollection = useMemo(() => {
    return theMatchResultsThatHaveCollections.includes(card.id);
  }, [theMatchResultsThatHaveCollections]);

  return (
    <div
      onClick={(event) => {
        event.stopPropagation(); //evitar propagação do click na função do bulk action
      }}
      className="ml-auto gap-2 flex flex-wrap  "
      {...props}
    >
      <Button
        size="sm"
        onClick={openIntegra}
        appearance="secondary"
        disabled={!card.url}
        iconPosition="left"
        icon={<ExportSquare size={16} color="#98A2B3" />}
        tooltipText="Abrir a íntegra"
      >
        {isInMonitoring ? '' : 'Íntegra'}
      </Button>

      <CiteButton
        cards={[card]}
        icon={<Copy size={16} color="#475467" />}
        appearance="secondary"
        pageView={pageView}
      >
        Citar
      </CiteButton>

      <SaveCardToCollectionButton
        cards={[card]}
        query={query}
        theCollections={theAllCollectionsInOne}
        isItInACollection={isItInACollection}
        icon={
          isItInACollection ? (
            <div style={{ padding: 0 }}>
              <ArchiveTick color="#32D583" size="19" variant="Bold" />
            </div>
          ) : (
            <div style={{ padding: 0 }}>
              <ArchiveAdd color="#667085" size="19" />
            </div>
          )
        }
        appearance="secondary"
        pageView={pageView}
      >
        Salvar
      </SaveCardToCollectionButton>

      <ResumeInteiroTeor card={card} pageView={pageView} />
    </div>
  );
};

export default withRouter(ResultButtonGroup);
