import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { requester } from '../requesters';
import { TURIVIUS_VERSION } from '../app/App';
import { satismaterData } from './satismater';
import { posthogData } from './posthog';
import { Bounce, toast } from 'react-toastify';

import { PostHogSendLogin } from '../utils/PostHogSendEvents';

export const makeTheGoogleLogin = (response, props, setStep) => {
  props.setPermissions(response?.permissions);
  localStorage.setItem('token', response.token);
  localStorage.setItem('permissions', JSON.stringify(response?.permissions));
  localStorage.setItem('user', response.id);
  localStorage.setItem('tagger', response.tagger);
  requester.setUserToken(response.token);
  requester.profiles
    .getOnboardingUserById(response.id)
    .then((profile) => {
      posthogData(profile);
      satismaterData(profile);

      if (profile.company.hasOwnProperty('blocked')) {
        if (profile.company.blocked) return props.history.push('/trial');
      }

      if (profile.active) {
        localStorage.setItem('company', profile.company.id);
        props.setCompany(profile.company);
        localStorage.setItem('profile', JSON.stringify(profile));
        props.setProfile(profile);
        localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));

        toast.success('Login realizado com sucesso.', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
          style: { margin: '80px' },
          transition: Bounce,
        });

        props.history.push('/');
      } else {
        setStep('login');
        localStorage.clear();
        toast.info(
          'Seu acesso está bloqueado! Entre em contato com a administrador da sua conta.',
          {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          },
        );
      }
    })
    .catch((err) => {
      setStep('login');
      switch (err.status) {
        default:
          toast.error(
            'Houve um problema de conexão, tente novamente em instantes.',
            {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce,
            },
          );
          break;
      }
      console.error(err);
    });
};

const onLogIn = (email, senha, setLoading, setErrorLogin, props) => {
  setLoading?.(true);
  requester.authentication
    .login(email, senha)
    .then((response) => {
      props.setPermissions(response?.permissions);
      localStorage.setItem('token', response.token);
      localStorage.setItem(
        'permissions',
        JSON.stringify(response?.permissions),
      );
      localStorage.setItem('user', response.id);
      localStorage.setItem('tagger', response.tagger);
      requester.setUserToken(response.token);
      return requester.profiles.getById(response.id);
    })
    .then((profile) => {
      posthogData(profile);
      satismaterData(profile);

      if (profile.company.hasOwnProperty('blocked')) {
        if (profile.company.blocked) return props.history.push('/trial');
      }

      if (profile.active) {
        localStorage.setItem('company', profile.company.id);
        props.setCompany(profile.company);
        localStorage.setItem('profile', JSON.stringify(profile));
        props.setProfile(profile);
        localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));

        PostHogSendLogin(); // POSTHOG_NEW

        toast.success('Login realizado com sucesso.', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
          transition: Bounce,
        });

        props.history.push('/');
      } else {
        localStorage.clear();
        toast.success(
          'Seu acesso está bloqueado! Entre em contato com a administrador da sua conta.',
          {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          },
        );
      }
    })
    .catch((err) => {
      setLoading?.(false);
      switch (err.status) {
        case 400:
          setErrorLogin?.(true);
        default:
          toast.error(
            'Houve um problema de conexão, tente novamente em instantes.',
            {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce,
            },
          );
          break;
      }
      console.error(err);
    });
};

const onFirstLogIn = async (response, setLoading, props) => {
  setLoading?.(true);
  props.setPermissions(response?.permissions);
  localStorage.setItem('token', response.token);
  localStorage.setItem('permissions', JSON.stringify(response?.permissions));
  localStorage.setItem('user', response.id);
  localStorage.setItem('tagger', response.tagger);
  requester.setUserToken(response.token);

  const profile = await requester.profiles.getOnboardingUserById(response.id);
  posthogData(profile);
  satismaterData(profile);

  if (profile.company.hasOwnProperty('blocked')) {
    if (profile.company.blocked) return props.history.push('/trial');
  }

  if (profile.active) {
    localStorage.setItem('company', profile.company.id);
    props.setCompany(profile.company);
    localStorage.setItem('profile', JSON.stringify(profile));
    props.setProfile(profile);
    localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));

    toast.success('Bem-vindo(a) à Turivius!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });

    props.history.push('/');
  } else {
    localStorage.clear();
    toast.success(
      'Seu acesso está bloqueado! Entre em contato com a administrador da sua conta.',
      {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      },
    );
  }
};

const onLogInSSO = (email, setLoading, setError) => {
  setLoading(true);

  let host = email.split('@', 2)[1];
  requester.sso
    .getSSO(host)
    .then((data) => {
      if (data.url) {
        window.location.href = data.url;
      } else {
        setLoading(false);
        setError(true);
      }
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
      setError(true);
    });
};

const SSOInformation = (token, setLoading, props) => {
  setLoading(true);

  requester.setUserToken(token);
  requester.sso.getUser().then((data) => {
    props.setPermissions(data?.permissions);
    localStorage.setItem('token', token);
    localStorage.setItem('permissions', JSON.stringify(data?.permissions));
    localStorage.setItem('user', data.id);
    localStorage.setItem('tagger', data.tagger);
    this.profile(data.id);
  });

  /*login firebase*/
  const auth = getAuth();
  signInWithEmailAndPassword(auth, 'vitor@turivius.com', 'Turivius@2023')
    .then((userCredential) => {
      const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
    });
};

const onChangePassword = (email) => {};
const onSendCodePassword = (email) => {};

export {
  onLogIn,
  onLogInSSO,
  onFirstLogIn,
  SSOInformation,
  onChangePassword,
  onSendCodePassword,
};
