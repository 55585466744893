import { Turivius } from '../../../ui/';
import { requester } from '../../../requesters';
import React, { useEffect, useState } from 'react';
import { Whisper, Popover, Progress, Button } from 'rsuite';

const getDaysUntilEndOfMonth = () => {
  const today = new Date(); // Data atual
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Último dia do mês atual

  const diffTime = lastDayOfMonth.getTime() - today.getTime(); // Diferença em milissegundos
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Converter para dias

  return diffDays;
};

const CreditPopover = ({ usedCredit, totalCredit }) => {
  if (totalCredit == 0) return;

  return (
    <div className="credit-div-box">
      <Whisper
        placement="top"
        trigger="hover"
        speaker={
          <Popover title="Saldo" className="popover-upgrade">
            <p style={{ padding: '0px 0px 0px 12px' }}>
              {totalCredit === -1 ? (
                'Seu plano tem saldo ilimitado'
              ) : (
                <>
                  Você consultou{' '}
                  <strong>
                    {usedCredit} {usedCredit === 1 ? 'CNPJ' : 'CNPJs'}
                  </strong>{' '}
                  do total de{' '}
                  <strong>
                    {totalCredit}{' '}
                    {totalCredit === 1 ? 'permitido' : 'permitidos'}{' '}
                  </strong>{' '}
                  no seu plano.
                </>
              )}
            </p>
            {totalCredit === -1 ? (
              ''
            ) : (
              <>
                <hr style={{ margin: '16px 0px' }}></hr>
                <p
                  style={{
                    padding: '0px 12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <strong>Utilização</strong>
                  {((usedCredit / totalCredit) * 100)?.toFixed(2)} %
                </p>

                <Progress.Line
                  showInfo={false}
                  status="active"
                  strokeColor="#1751D0"
                  style={{ height: '0px !important' }}
                  percent={(usedCredit / totalCredit)?.toFixed(2) * 100}
                />
                <p style={{ padding: '0px 12px' }}>
                  Seu saldo reseta em:{' '}
                  <strong>
                    {getDaysUntilEndOfMonth() == 1
                      ? 'Amanhã'
                      : getDaysUntilEndOfMonth() + ' dias'}
                  </strong>
                </p>
              </>
            )}
          </Popover>
        }
      >
        <Button
          className="btn-upgrade-modal"
          appearance="link"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Ver meu saldo
        </Button>
      </Whisper>
    </div>
  );
};

export default CreditPopover;
