import React from 'react';
import { useState } from 'react';
import { SelectPicker } from 'rsuite';
// import "./InformativeIcons.scss";

import { PostHogSendMonitoring } from '../../../utils/PostHogSendEvents';

const dropdownData = [
  { id: 4, value: '-updated_date', label: 'Última Atualização' },
  { id: 1, value: 'query', label: 'Ordem Alfabética (a-z)' },
  { id: 2, value: '-query', label: 'Ordem Alfabética (z-a)' },
  {
    id: 3,
    value: '-registration_date',
    label: 'Data de Criação (mais recente)',
  },
  { id: 5, value: 'registration_date', label: 'Data de Criação (mais antigo)' },
];

const OrderBy = ({ changeFilters, whereSearch, monitoringCount }) => {
  const [order, setOrder] = useState('-updated_date');

  function search() {
    changeFilters({ where: whereSearch, ordering: order });
    PostHogSendMonitoring('order_by_monitoring'); // POSTHOG_NEW
  }

  return (
    <div className="monitoring-order-by">
      <label className="monitoring-order-by_label">
        <b>Ordenar por:</b>
      </label>
      <SelectPicker
        block
        data={dropdownData}
        style={{ margin: '10px 0px' }}
        placeholder={'Selecione uma ordenação'}
        value={order}
        onChange={setOrder}
        onClose={search}
        cleanable={false}
        disabled={monitoringCount === 0}
      />
    </div>
  );
};

export default OrderBy;
