import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import './CustomCheckTree.css';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { ArrowDown2, ArrowRight2, ArrowUp2, Pointer } from 'iconsax-react';

export const CustomCheckTree = ({
  filters,
  filtersProps,
  filterReference,
  updateSelectedFilters,
  isHome,
  clear,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(filters[0]['options']);
  const [isCheckeds, setIsCheckeds] = useState([]);
  const [labelsOpen, setLabelsOpen] = useState([]);
  const [viewMoreOpen, setViewMoreOpen] = useState([]);
  const url = new URL(window.location.href);
  const location = useLocation(); // Hook para capturar mudanças de rota

  useEffect(() => {
    setIsCollapsed(true); // Sempre colapsa ao mudar a página
  }, [location.pathname]); // Executa toda vez que a rota muda

  const getUrlParameters = () => {
    const url = new URL(window.location.href);
    const params = {};
    url.searchParams.forEach((value, key) => {
      try {
        if (key === 'fields' || key === 'filters' || key === 'entity') {
          try {
            params[key] = JSON.parse(decodeURIComponent(value));
          } catch {
            params[key] = JSON.parse(value);
            console.log('Quatum computing failed at filters'); // EXPLANATION: Solução paleativa
          }
        } else {
          try {
            params[key] = decodeURIComponent(value);
          } catch {
            params[key] = value;
            console.log('Quatum computing failed'); // EXPLANATION: Solução paleativa
          }
        }
      } catch (e) {
        params[key] = decodeURIComponent(value);
      }
    });
    setIsCheckeds(
      params.filters?.entity || params.filters?.entities || filters[0].default,
    );
    const storedFilters = JSON.parse(localStorage.getItem('filters')) || {};
    storedFilters.entities = params.filters?.entity;
    localStorage.setItem('filters', JSON.stringify(storedFilters));
    return params;
  };

  function extractLabelsWithChildren() {
    let result = [];
    filters[0]['options'][0] &&
      filters[0]['options'][0].children.map((item) => {
        if (item.children && item.children.length > 0) {
          result.push(item.label);
          item.children.map((item2) => {
            if (item2.children && item2.children.length > 0) {
              result.push(item2.label);
            }
          });
        }
      });

    return result; // EXPLANATION: Eu retorno esse cara e uso o setLAbelOpenbs apenas para o botão de maximizar
    // setLabelsOpen(result); // EXPLANATION: Esse filtro executar aqui faz com que ele entre aberto nos resultados
  }

  useEffect(() => {
    if (isHome) {
      extractLabelsWithChildren();
    }
  }, [filters]);

  useEffect(() => {
    getUrlParameters();
  }, []);

  useEffect(() => {
    const params = {};
    url.searchParams.forEach((value, key) => {
      try {
        if (key === 'fields' || key === 'filters' || key === 'entity') {
          try {
            params[key] = JSON.parse(decodeURIComponent(value));
          } catch {
            params[key] = JSON.parse(value);
            console.log('Quantum computing failed', key);
          }
        } else {
          try {
            params[key] = decodeURIComponent(value);
          } catch {
            params[key] = value;
            console.log('Quantum computing failed', key);
          }
        }
      } catch (e) {
        params[key] = decodeURIComponent(value);
      }
    });

    if (params.filters?.entity) {
      setIsCheckeds(params.filters?.entity);
    }
  }, [url]);

  const filterTreeData = (data, searchValue) => {
    if (!searchValue) return data;

    const lowerSearch = searchValue.toLowerCase();

    return data
      .map((item) => {
        if (item.children) {
          const filteredChildren = filterTreeData(item.children, searchValue);

          if (
            filteredChildren.length > 0 ||
            item.label.toLowerCase().includes(lowerSearch)
          ) {
            return { ...item, children: filteredChildren };
          }
        }

        return item.label.toLowerCase().includes(lowerSearch)
          ? { ...item }
          : null;
      })
      .filter(Boolean);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setFilteredData(filterTreeData(filters[0]['options'], e.target.value));
    extractLabelsWithChildren();
  };

  const clearSearch = () => {
    setSearchValue('');
    setFilteredData(filterTreeData(filters[0]['options'], ''));
    extractLabelsWithChildren();
  };

  useEffect(() => {
    if (labelsOpen.length > 0) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [labelsOpen]);

  const handleExpandCollapse = (label) => {
    setLabelsOpen((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        return [...prev, label];
      }
    });
  };

  const expandVisualization = (label) => {
    setViewMoreOpen((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        return [...prev, label];
      }
    });
  };

  const updateUrlWithSelectedEntities = (selected) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    const storedFilters = JSON.parse(localStorage.getItem('filters')) || {};
    storedFilters.entities = selected;
    localStorage.setItem('filters', JSON.stringify(storedFilters));

    params.set('filters', JSON.stringify(storedFilters));
    const queryParams = decodeURIComponent(params.toString());
    if (window.location.pathname == '/pesquisa/resultados') {
      window.history.replaceState({}, '', `${url.pathname}?${queryParams}`);
    }
  };

  const updateLocalStorageFilters = (selectedEntities) => {
    const storedFilters = JSON.parse(localStorage.getItem('filters')) || {};
    storedFilters.entities = selectedEntities;
    localStorage.setItem('filters', JSON.stringify(storedFilters));
  };

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const changeEntityCheckbox = (item) => {
    setIsCheckeds((prevItems) => {
      if (item.children) {
        const childrenValues = item.value;

        const estaChecked = checkArrayStatus(childrenValues, prevItems);

        if (estaChecked === true) {
          return prevItems.filter((value) => !childrenValues.includes(value));
        }

        return [...new Set([...prevItems, ...childrenValues])];
      }

      if (prevItems.includes(item.value)) {
        return prevItems.filter((value) => value !== item.value);
      } else {
        return [...prevItems, item.value];
      }
    });
  };

  useEffect(() => {
    filtersProps.setTmpFilters(filterReference, isCheckeds);
    updateSelectedFilters({ [filterReference]: isCheckeds });
    updateLocalStorageFilters(isCheckeds);
    updateUrlWithSelectedEntities(isCheckeds);
  }, [isCheckeds]);

  const getEntityCount = (nodeValue, value_counts) => {
    const isGroupSelection = typeof nodeValue === 'object';
    let arrayValues = [];

    if (!isGroupSelection) {
      arrayValues = [nodeValue];
    } else {
      arrayValues = [...nodeValue];
    }

    let count = Number(
      Object.entries(value_counts)
        .filter(([entity_id, count]) => arrayValues.includes(Number(entity_id)))
        .reduce(
          (accumulator, [entity_id, entity_count]) =>
            accumulator + entity_count,
          0,
        ),
    );

    return count;
  };

  const checkArrayStatus = (subsetArray, mainArray) => {
    if (mainArray.length === 0) {
      return false;
    }

    if (!Array.isArray(subsetArray) || subsetArray.length === 0) {
      return 'Subset array inválido ou vazio';
    }

    if (!Array.isArray(mainArray) || mainArray.length === 0) {
      return 'Main array inválido ou vazio';
    }

    const allPresent = subsetArray.every((item) => mainArray.includes(item));
    const nonePresent = subsetArray.every((item) => !mainArray.includes(item));

    if (allPresent) {
      return true;
    } else if (nonePresent) {
      return false;
    } else {
      return 'Alguns';
    }
  };

  useEffect(() => {
    if (clear) {
      const allOptions = [];

      const collectOptions = (items) => {
        items.forEach((item) => {
          if (item.value && item.value.length > 0) {
            allOptions.push(item.value);
          }
          if (item.children && item.children.length > 0) {
            collectOptions(item.children);
          }
        });
      };

      collectOptions(filters[0]?.options || []);

      const uniqueOptions = [...new Set(allOptions.flat())];

      setIsCheckeds(uniqueOptions);
    }
  }, [clear, filters]);

  return (
    <div className="tv-select_collapsable">
      <div
        className="tv-select_collapsable-header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span className="title-filter">Selecione os órgãos</span>
        {isCollapsed ? (
          <ArrowUp2
            style={{ cursor: 'pointer' }}
            onClick={() => {
              toggleCollapse();
              setLabelsOpen([]);
            }}
            size={24}
            color="#98A2B3"
          />
        ) : (
          <ArrowDown2
            size={24}
            color="#98A2B3"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              toggleCollapse();
              const result = extractLabelsWithChildren();
              setLabelsOpen(result);
            }}
          />
        )}
      </div>

      <div className="tv-select_search-container">
        <img src="/lupa.png" alt="Ícone de busca" className="search-icon" />
        <input
          type="text"
          placeholder="Buscar o órgão por nome"
          style={{ paddingLeft: '30px' }}
          className="tv-select_search-input"
          onChange={handleSearchChange}
          value={searchValue}
        />
        {searchValue && (
          <button className="clear-search" onClick={clearSearch}>
            <CloseRoundedIcon style={{ fontSize: 14 }}></CloseRoundedIcon>
          </button>
        )}
      </div>
      <div className="content-filters">
        <div className="box-checkbox">
          <input
            type="checkbox"
            id="scales"
            name="scales"
            onClick={(e) => changeEntityCheckbox(filteredData[0])}
            readOnly
            checked={filters[0]?.default.every((item) =>
              isCheckeds.includes(item),
            )}
            ref={(el) => {
              if (el) {
                el.indeterminate =
                  checkArrayStatus(filteredData[0]?.value, isCheckeds) ===
                  'Alguns'
                    ? true
                    : false;
              }
            }}
          />{' '}
          Todos
        </div>
        {filteredData[0] &&
          filteredData[0].children.map((item) => {
            const isExpanded = labelsOpen.includes(item.label);
            const list =
              item.children && viewMoreOpen.includes(item.label)
                ? item?.children
                : !item.children.some(
                      (child) => child.children && child.children.length > 0,
                    )
                  ? item?.children
                  : item?.children;

            return (
              <div className="box-allcheckbox" key={item.label}>
                <div className="content-checkbox">
                  <div className="box-content-two">
                    {item.children &&
                      (isExpanded ? (
                        <ArrowDown2
                          size={12}
                          color="#667085"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleExpandCollapse(item.label)}
                        />
                      ) : (
                        <ArrowRight2
                          size={12}
                          color="#667085"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleExpandCollapse(item.label)}
                        />
                      ))}
                    <input
                      type="checkbox"
                      onClick={(e) => changeEntityCheckbox(item)}
                      id="scales"
                      name="scales"
                      readOnly
                      checked={
                        item.children
                          ? checkArrayStatus(item.value, isCheckeds)
                          : isCheckeds?.includes(item.value)
                      }
                      ref={(el) => {
                        if (el) {
                          el.indeterminate =
                            checkArrayStatus(item.value, isCheckeds) ===
                            'Alguns'
                              ? true
                              : false;
                        }
                      }}
                    />
                    {item.label}
                  </div>
                  <span className="entity-count">
                    {isHome
                      ? getEntityCount(
                          item.value,
                          filtersProps.entities_counter,
                        ) || 0
                      : 0}
                  </span>
                </div>

                {isExpanded &&
                  list &&
                  list.map((item2) => {
                    const isExpanded2 = labelsOpen.includes(item2.label);
                    const list2 =
                      item2.children && viewMoreOpen.includes(item2.label)
                        ? item2?.children
                        : item2?.children;
                    return (
                      <div
                        className="content-checkbox-children"
                        key={item2.value}
                      >
                        <div
                          className={
                            item2?.children
                              ? `content-checkbox`
                              : 'content-checkbox without-children '
                          }
                        >
                          <div className="box-content-two">
                            {item2.children &&
                              (isExpanded2 ? (
                                <ArrowDown2
                                  size={12}
                                  color="#667085"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleExpandCollapse(item2.label)
                                  }
                                />
                              ) : (
                                <ArrowRight2
                                  size={12}
                                  color="#667085"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleExpandCollapse(item2.label)
                                  }
                                />
                              ))}
                            <input
                              type="checkbox"
                              id="scales"
                              name="scales"
                              readOnly
                              checked={
                                item2.children
                                  ? checkArrayStatus(item2.value, isCheckeds)
                                  : isCheckeds?.includes(item2.value)
                              }
                              onClick={() => changeEntityCheckbox(item2)}
                              ref={(el) => {
                                if (el && item2.children) {
                                  const status = checkArrayStatus(
                                    item2.children.map((child) => child.value),
                                    isCheckeds,
                                  );
                                  el.indeterminate = status === 'Alguns';
                                }
                              }}
                            />
                            {item2.label}
                          </div>
                          <span
                            className={
                              item2?.children
                                ? `entity-count`
                                : 'entity-count-two '
                            }
                          >
                            {isHome
                              ? getEntityCount(
                                  item2.value,
                                  filtersProps.entities_counter,
                                ) || 0
                              : 0}
                          </span>
                        </div>

                        {isExpanded2 &&
                          list2 &&
                          list2.map((item3) => {
                            return (
                              <div
                                className="content-checkbox-children-two"
                                key={item3.value}
                              >
                                <div className="content-children">
                                  <div className="box-content-two">
                                    {item3.children && (
                                      <ArrowDown2 size={12} color="#667085" />
                                    )}
                                    <input
                                      type="checkbox"
                                      id="scales"
                                      readOnly // EXPLANATION: mitigate check error on debug console
                                      checked={isCheckeds?.includes(
                                        item3.value,
                                      )}
                                      name="scales"
                                      onClick={(e) =>
                                        changeEntityCheckbox(item3)
                                      }
                                    />
                                    {item3.label}
                                  </div>
                                  <span className="entity-count-two">
                                    {isHome
                                      ? getEntityCount(
                                          item3.value,
                                          filtersProps.entities_counter,
                                        ) || 0
                                      : 0}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        {isExpanded2 &&
                          item2.children &&
                          item2.children.length > 100 && (
                            <div>
                              {viewMoreOpen.includes(item2.label) ? (
                                <div
                                  className="btn-expand-collapse"
                                  onClick={() => {
                                    expandVisualization(item2.label);
                                  }}
                                >
                                  - Ver Menos
                                </div>
                              ) : (
                                <div
                                  className="btn-expand-collapse"
                                  onClick={() => {
                                    expandVisualization(item2.label);
                                  }}
                                >
                                  + Ver Mais
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    );
                  })}
                <div>
                  {' '}
                  {isExpanded &&
                    item.children &&
                    !item.children.some(
                      (child) => child.children && child.children.length > 0,
                    ) &&
                    item.children.length > 1000 && (
                      <div>
                        {viewMoreOpen.includes(item.label) ? (
                          <div
                            className="btn-expand-collapse"
                            onClick={() => {
                              expandVisualization(item.label);
                            }}
                          >
                            - Ver Menos
                          </div>
                        ) : (
                          <div
                            className="btn-expand-collapse"
                            onClick={() => {
                              expandVisualization(item.label);
                            }}
                          >
                            + Ver Mais
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
